<template>
  <div>
    <div id="timeline-chart_2"></div>
  </div>
</template>
<script>
import ApexCharts from 'apexcharts';

export default {
  name: 'AreaChart',
  props: ['regiondata', 'years', 'uzbekistanData'],
  data() {
    return {
      gradient: null,
      gradient2: null,
      lineChart: null,
      currentLang: localStorage.getItem('language') ? localStorage.getItem('language') : 'uz',
    };
  },
  methods: {
    updateLineChartRegion(dataartices, dataRegions, id) {
      if (dataRegions.children === false) {
        const body = {
          dataUz: dataartices,
          dataRegion: dataRegions,
        };
        this.createLineChart(body);
      } else {
        const dataRegion = dataRegions.find((item) => item.id === id);
        const body = {
          dataUz: dataartices,
          dataRegion,
        };
        this.createLineChart(body);
      }
    },
    createLineChart(data) {
      const options = {
        chart: {
          type: 'area',
          height: 450,
          stacked: false,
          toolbar: {
            show: true,
            tools: {
              download: false,
              selection: false,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: false,
              reset: false,
            },
          },
          dropShadow: {
            enabled: true,
            enabledSeries: [0],
            top: -2,
            left: 2,
            blur: 5,
            opacity: 0.06,
          },
        },
        colors: ['#2CB16D', '#3F8DFD'],
        stroke: {
          curve: 'smooth',
          width: 3,
        },
        dataLabels: {
          enabled: true,
          offsetY: -5,
          style: {
            padding:10,
            fontSize: '14px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 'bold',
            colors: undefined,
        },
        background: { 
        enabled: true,
        foreColor: '#153462',
        padding: 4,
        colors: undefined,
        opacity: 0,
        
      },
        },
        series: [{
          name: data.dataUz ? data.dataUz.title : 'null',
          data: data.dataUz ? data.dataUz.values : ['2000', 18],
        }, {
          name: data.dataRegion ? data.dataRegion.title : 'null',
          data: data.dataRegion ? data.dataRegion.values : ['2000', 118],
        }],
        markers: {
          size: 5,
        },
        yaxis: {
          tooltip: {
            enabled: true,
          },
        },
        grid: {
          padding: {
            left: 20,
            right: 40,
          },
        },
        tooltip: {
          x: {
            format: 'yyyy',
          },
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 0, 0],
          },
        },
      };
      if (this.lineChart) {
        this.lineChart.updateSeries([{
          name: data.dataUz ? data.dataUz.title : 'null',
          data: data.dataUz ? data.dataUz.values : ['2000', 18],
        }, {
          name: data.dataRegion ? data.dataRegion.title : 'null',
          data: data.dataRegion ? data.dataRegion.values : ['2000', 118],
        }]);
      } else {
        this.lineChart = new ApexCharts(document.querySelector('#timeline-chart_2'), options);
        this.lineChart.render();
      }
    },
  },
  computed: {
    regiondataVoit() {
      return this.regiondata;
    },
    yearsVoit() {
      return this.years;
    },
    uzbekistanDataVoit() {
      return this.uzbekistanData;
    },
  },
};
</script>
