<template>
  <div class="regionChart">
    <div>
      <div class="region-reytong-head">
        <h2>{{ fatherRegion }}</h2>
        <button @click="downloadSvg" class="download-svg">{{ $t("downloadPng") }}</button>
      </div>
    </div>
    <canvas id="myChart2"></canvas>
    <div class="chart-js-labels">
      <button
        v-for="label in this.currentLabes.slice(1)"
        :key="label.id"
        :class="{ noActive: removeItems.find((items) => items == label.id) }"
        @click="changeRegionChart(label.id)"
      >{{ label.text }}
      </button>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js';
import Vue from 'vue';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';

Vue.prototype.$Chart = Chart;

export default {
  name: 'minichartBar',
  props: {
    chartBarYear: {
      type: [Number, String],
      default: 2013,
    },
    reytingTitle: {
      type: String,
    },
  },
  data() {
    return {
      data: [],
      ChartBar: '',
      fatherRegion: '',
      backgroundColor: [],
      labelsBar: [],
      currentData: [],
      currentLabes: [],
      removeItems: [],
    };
  },
  methods: {
    changeRegionChart(id) {
      const findId = this.removeItems.findIndex((item) => item === id);
      if (findId === -1) {
        this.removeItems.push(id);
      } else {
        this.removeItems.splice(findId, 1);
      }
      this.createCharBar(this.currentData, true);
    },
    downloadSvg() {
      const svg = document.querySelector('.regionChart');
      if (svg == null) {
        alert(this.$t('pleaseWaitForDiagramFullyLoad'));
      } else {
        document.querySelector('.download-svg').style.display = 'none';
        html2canvas(svg).then((canvas) => {
          canvas.toBlob((blob) => {
            saveAs(blob, `${this.fatherRegion}Image.jpg`);
          });
        });
        document.querySelector('.download-svg').style.display = 'block';
      }
    },
    createCharBar(datas, local) {
      this.fatherRegion = datas[0].text;
      const currentDatas = datas.map((item) => (item));
      if (this.ChartBar) {
        this.ChartBar.destroy();
      }
      this.backgroundColor = datas.map(() => 'rgba(63, 141, 253, 1)');
      for (let i = 0; i < this.backgroundColor.length; i += 1) {
        this.backgroundColor[i] = 'rgba(63, 141, 253, 1)';
      }
      currentDatas.sort((a, b) => b.value - a.value);
      this.removeItems.forEach((item) => {
        currentDatas.splice(
          currentDatas.findIndex((items) => items.id === item),
          1,
        );
      });
      if (!local) {
        this.currentLabes = datas.map((item) => ({ text: item.text, id: item.id }));
        this.currentData = datas.map((item) => (item));
      }
      const data = this.NewData(currentDatas);
      this.backgroundColor[
        data.data.labels.findIndex((item) => item === "O'zbekiston Respublikasi")
      ] = 'rgba(44, 177, 109, 1)';
      const ctx = document.getElementById('myChart2');
      this.ChartBar = new this.$Chart(ctx, data);
    },
    NewData(data) {
      this.data = data;


    if (this.data[0].text === "O'zbekiston Respublikasi" || this.data[0].text === "Республика Узбекистан" || this.data[0].text === "Republic of Uzbekistan" ){

      } else{
        this.data.shift(0)

      }







      const labels = data.map((item) => item.text);
      const values = data.map((item) => item.value);
      return {
        type: 'bar',
        data: {
          labels,
          datasets: [
            {
              label: this.title,
              data: values,
              id: values,
              borderWidth: 1,
              hoverBackgroundColor: 'rgba(44, 177, 109, 1)',
              backgroundColor: this.backgroundColor,
            },
          ],
        },
        options: {
          plugins: {
            colorschemes: {
              scheme: 'brewer.Pastel1-3',
            },
          },
          legend: {
            position: 'bottom',
            display: false,
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  fontColor: 'rgba(86, 102, 131, 1)',
                  autoSkip: false,
                  maxRotation: 90,
                  minRotation: 90,
                },
              },
            ],
            yAxes: [{
              ticks: {
                beginAtZero: true
              }
            }],
          },
          onClick: this.graphClickEvent,
        },
      };
    },
    graphClickEvent(event, array) {
      if (array[0]) {
        // eslint-disable-next-line no-underscore-dangle
        this.$emit('clickBar', array[0]._view.label);
      }
    },
  },
  computed: {
    Year() {
      return this.chartBarYear;
    },
    title() {
      return this.reytingTitle;
    },
  },
};
</script>

<style lang="scss">
.region-reytong-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 130%;
    color: #2e3236;
  }
}
</style>
