<template>


    


    
    <v-dialog 
        v-model="dialog"
        transition="dialog-top-transition"
        max-width="1400"
    >
      <template v-slot:activator="{ on, attrs }">
        
        <div 
          v-bind="attrs"
          v-on="on"
          style="display: flex;
"
          >
        
                <div style="margin-top: 5px;">
                  <svg   xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style="width: 18px; height: 18px; color: rgb(31, 123, 252); " class="bi bi-exclamation-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"/>
                  </svg>
                </div>

                <div>
                  <span  style="color: #0071bc; margin-left: 10px;">{{ $t("attention") }}</span>
                </div>
          
                </div>
        
      </template>

      <v-card>
        <div style="position: fixed; padding: 0 69%;">
          <v-btn
                
                text
                @click="dialog = false"
              >x</v-btn>
        </div>
        <v-card-title class="text-h5 text-xs-center justify-center">
          {{ this.relationship.breadCrumb[2].text }}
         
        
        </v-card-title>

        <v-card-text>
          <main>
            <div class="container">
              
              <div class="about-list">
                
                <div class="about-list__item" v-for="(step, i) in this.relationship.listSteps" :key="i">
                  <div class="about-list__left" >
                    <div class="about__header">
                      <div class="count-wrapper">
                        <span>
                          {{ i + 1 }}
                        </span>
                      </div>
                      <span class="step">{{ $t('step') }}</span>
                    </div>
                    <p class="about-list__text" v-html="step.text"></p>
                  </div>
                  <div class="about-list__right">
                    <img
                      height="271"
                      @click="openImage(step.img)"
                      :src="
                      require(`@/assets/imgs/${step.img == 'default' ? 'default-image.svg' : step.img}`)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </main>
          <v-dialog
            v-model="imageShowModal"
            width="75vw"
          >
            <div class="modal-image-wrapper">
              <span
                @click="imageShowModal = false"
                class="icon icon-close modal-image-wrapper-icon-close"
              ></span>
              <img
                class="modal-image-wrapper-img"
                :src="require(`@/assets/imgs/${imageShowModalImg}`)" alt="modal image"
              />
            </div>
          </v-dialog>
        </v-card-text>

        

        
      </v-card>
    </v-dialog>
 
 
 </template>
  
  <script>

  /* eslint-disable global-require */
  
  export default {
    name: 'UsingSystem',
   
    data() {
      return {
        dialog: false,
        imageShowModal: false,
        imageShowModalImg: 'default-image.svg',
        
        relationship: {
        breadCrumb: [
          {
            text: this.$t('main'),
            disabled: false,
            link: true,
            exact: true,
            to: {
              path: '/',
            },
          },
          {
            text: this.$t('aboutSystem'),
            disabled: false,
            link: true,
            exact: true,
            to: {
              path: '/about-us',
            },
          },
          {
            text: this.$t('usingtheRelationship'),
            disabled: true,
            link: true,
            exact: true,
          },
        ],
        listSteps: [
          {
            img: 'MUNOSABAT_1-min.png',
            text: this.$t('aboutSystemRelationshipStep1'),
          },
          {
            img: 'MUNOSABAT_2-min.png',
            text: this.$t('aboutSystemRelationshipStep2'),
          },
          {
            img: 'MUNOSABAT_3-min.png',
            text: this.$t('aboutSystemRelationshipStep3'),
          },
          {
            img: 'MUNOSABAT_4-min.png',
            text: this.$t('aboutSystemRelationshipStep4'),
          },
          {
            img: 'MUNOSABAT_5-min.png',
            text: this.$t('aboutSystemRelationshipStep5'),
          },
        ],
      },
        menu: this.$route.params.menu,
      };
    },
    methods: {
      openImage(url) {
        this.imageShowModalImg = url;
        this.imageShowModal = true;
      },
    },
    mounted() {
      window.scrollTo(0, 0);
    },
  };
  </script>
  
  <style lang="scss" src="./AttentionMunosabat.scss"></style>
  