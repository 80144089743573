<template>
  <div>
    <div class="loading-component-bar" v-show="componentLoading">
      <span class="icon icon-loader"></span>
    </div>
    <div class="reyting-dialog-wrapper" v-show="!componentLoading">
      <div class="reyting-dialog-wrapper-years">
        <div class="years-wrapper w-100">
          <h2 id="years-title">{{ $t("chooseYear") }}</h2>
          <div class="years-actions">
            <div class="years-btns-play">
              <button @click="setPlay()" :class="{ active: play }">
                <span class="icon icon-play"></span>
              </button>
            </div>
            <div
              class="years-btns"
              :class="{ disable: play }"
              v-bind:style="{ height: buttonShowline * 66 + 'px' }"
            >
              <button
                v-for="year in years"
                :key="year.value"
                @click="setYear(year.value)"
                :class="{ active: year.active }"
              >
                {{ year.name }}
              </button>
            </div>
          </div>
          <button
            v-if="buttonShowline * 9 < years.length"
            @click="buttonShowline += 1"
            class="years-add-button"
          >
            {{ $t("seeMore") }}
          </button>
        </div>
      </div>
      <div class="reyting-dialog-wrapper-dialog">
        <chart-barc
          :data="ChartData"
          :backgroundColor="bacgroundColors"
          :chartBarYear="chartBarYear"
          :reytingTitle="reytingTitle"
          @clickBar="createMiniBar"
          @refreshChart="refreshChart"
          ref="ChartBar"
        />
      </div>
      <div
        class="reyting-dialog-wrapper-dialog"
        :class="showMiniRegionList ? 'show' : 'hide'"
      >
        <region-chart-bar
          :data="ChartData"
          :backgroundColor="bacgroundColors"
          :chartBarYear="chartBarYear"
          :reytingTitle="reytingTitle"
          ref="RegionCharBar"
        />
      </div>
    </div>
  </div>
</template>
//
<script>
import Api from '@/api/Api';
import ChartBarc from './components/ChartBarc.vue';
import RegionChartBar from './components/RegionChartBar.vue';

export default {
  name: 'ReytingdiagramaComponent',
  components: {
    ChartBarc,
    RegionChartBar,
  },
  data() {
    return {
      ChartData: [],
      regionChartData: [],
      chartBarYear: '2022',
      reytingTitle: 'Aholi soni',
      bacgroundColors: [
        'rgba(192, 199, 212, 1)',
        'rgba(192, 199, 212, 1)',
        'rgba(192, 199, 212, 1)',
        'rgba(192, 199, 212, 1)',
        'rgba(192, 199, 212, 1)',
        'rgba(192, 199, 212, 1)',
        'rgba(192, 199, 212, 1)',
        'rgba(192, 199, 212, 1)',
        'rgba(192, 199, 212, 1)',
        'rgba(192, 199, 212, 1)',
        'rgba(192, 199, 212, 1)',
        'rgba(192, 199, 212, 1)',
        'rgba(192, 199, 212, 1)',
        'rgba(192, 199, 212, 1)',
        'rgba(192, 199, 212, 1)',
      ],
      yearsData: [
        {
          title: '2000',
        },
        {
          title: '2001',
        },
        {
          title: '2002',
        },
        {
          title: '2003',
        },
        {
          title: '2004',
        },
        {
          title: '2005',
        },
        {
          title: '2006',
        },
        {
          title: '2007',
        },
        {
          title: '2008',
        },
        {
          title: '2009',
        },
        {
          title: '2010',
        },
        {
          title: '2011',
        },
        {
          title: '2012',
        },
        {
          title: '2013',
        },
      ],
      currentYear: new Date().getFullYear(),
      currentIndex: 0,
      showMiniRegionList: false,
      years: [],
      play: false,
      time: null,
      currentId: 0,
      buttonShowline: 2,
      currentLang: localStorage.getItem('language')
        ? localStorage.getItem('language')
        : 'uz',
      componentLoading: true,
    };
  },
  methods: {
    setYear(year) {
      this.chartBarYear = year;
      this.getReytingData(this.currentId);
      this.showMiniRegionList = false;
      this.years.forEach((el) => {
        const isEqual = el.value === year;
        if (isEqual) {
          // eslint-disable-next-line no-param-reassign
          el.active = true;
          this.currentYear = year;
        } else {
          // eslint-disable-next-line no-param-reassign
          el.active = false;
        }
      });
    },
    refreshChart() {
      this.getReytingData(this.currentId);
    },
    setYears() {
      this.currentIndex = this.years.findIndex(
        (el) => String(el.value) === String(this.currentYear),
      );
      this.currentIndex += 1;
      if (this.currentIndex >= this.years.length) {
        this.currentIndex = 0;
        this.currentYear = this.years[this.currentIndex].value;
        this.setYear(this.currentYear);
        this.getData();
      } else {
        this.currentYear = this.years[this.currentIndex].value;
        this.setYear(this.currentYear);
        this.getData();
      }
    },
    async setPlay() {
      this.play = !this.play;
      if (this.play) {
        this.setYears();
      } else {
        clearTimeout(this.timer);
      }
    },
    async getReytingData(id) {
      const { data } = await Api.getRaytingData(id, {
        year: this.chartBarYear,
      });
      this.ChartData = data.map((item) => {
        const { id: itemId, name_uz: name, data: itemData } = item;
        const value = Object.keys(itemData[0])
          .map((key) => itemData[0][key])
          .join();
        return {
          id: itemId,
          text: item[`name_${this.currentLang}`]
            ? item[`name_${this.currentLang}`]
            : name,
          value,
        };
      });
      const {
        data: { regions },
      } = await Api.getRegionData(this.currentId);
      this.$refs.ChartBar.createCharBar(this.ChartData, regions);
      this.componentLoading = false;
    },
    async getData() {
      this.timer = setTimeout(this.setYears, 5000);
    },
    async getYearsData(id) {
      this.currentId = id;
      const { data } = await Api.getYearsData(id);
      this.years = data.years.map((year) => ({
        name: year,
        value: year,
        active: false,
      }));
      this.buttonShowline = this.years.length > 9 ? 2 : 1;
      this.setYear(this.years[0].value);
      this.reytingTitle = data.name;
    },
    getClassId(name) {
      const findData = this.ChartData.find((item) => item.text === name);
      return findData ? findData.id : 'uz';
    },
    async createMiniBar(id) {
      const classifierItem = this.getClassId(id);
      if (classifierItem !== 'uz') {
        const { data } = await Api.getRaytingData(this.currentId, {
          year: this.currentYear,
          classifier_item: classifierItem,
        });
        
	      if (data[0].children) {
          this.regionChartData = data[0].children.map((item) => {
            const { id: itemId, name, data: itemData } = item;
            const value = Object.keys(itemData[0])
              .map((key) => itemData[0][key])
              .join();
            return {
              id: itemId,
              text: item[`name_${this.currentLang}`]
                ? item[`name_${this.currentLang}`]
                : name,
              value,
            };
          });
          const firstElement = data.map((item) => {
            const { id: itemId, name, data: regionData } = item;
            const value = Object.keys(regionData[0])
              .map((key) => regionData[0][key])
              .join();
            return {
              id: itemId,
              text: item[`name_${this.currentLang}`]
                ? item[`name_${this.currentLang}`]
                : name,
              value,
            };
          });
          this.regionChartData.unshift(firstElement[0]);
          this.$refs.RegionCharBar.createCharBar(this.regionChartData);
          this.showMiniRegionList = true;
        } else {
          this.regionChartData = data.map((item) => {
            const { id: itemId, name, data: regionData } = item;
            const value = Object.keys(regionData[0])
              .map((key) => regionData[0][key])
              .join();
            return {
              id: itemId,
              text: item[`name_${this.currentLang}`]
                ? item[`name_${this.currentLang}`]
                : name,
              value,
            };
          });
          this.$refs.RegionCharBar.createCharBar(this.regionChartData);
        }
      }
    },
    changecomponentValue(id) {
      if (this.currentId !== id) {
        this.componentLoading = true;
        this.getYearsData(id);
        this.showMiniRegionList = false;
      }
    },
  },
  mounted() {
    this.$emit('ready');
  },
};
</script>

<style src="./ReytingdiagramaComponent.scss" lang="scss"></style>
