<template>
  <div>
    <div class="loading-component-bar" v-show="componentLoading">
      <span class="icon icon-loader"></span>
    </div>
    <div v-if="noData && !componentLoading" class="meta-information-wrapper">
      <h3>{{ $t("metadataNotAvailable") }}</h3>
    </div>
    <div
      v-show="!componentLoading && !noData"
      class="meta-information-wrapper"
    >
      <h3>{{ $t("passport") }}</h3>
      <div v-if="metaInformatinData.length">
        <h5>{{ $t("databaseIdentificationNumber") }}</h5>
        <p>{{ sdmxCode }}</p>
      </div>
      <div v-if="!metaInformatinData.length">
        <h5>{{ $t("metadataNotAvailable") }}</h5>
      </div>
      <div v-if="metaInformatinData.length">
        <h5>{{ $t("dateFirstPublication") }}</h5>
        <p>{{ createDate }}</p>
      </div>
      <div v-if="metaInformatinData.length">
        <h5>{{ $t("lastModifiedDate") }}</h5>
        <p>{{ updateDate }}</p>
      </div>
      <div v-for="item in metaInformatinData" :key="item.id">
        <h5>{{ item.name }}</h5>
        <p v-if="!item.link" v-html="item.value"></p>
        <a target="_blank" :href="item.value" v-if="item.link">{{
          item.value
        }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '@/api/Api';

export default {
  name: 'MetaDataComponent',
  data() {
    return {
      metaInformatinData: [],
      createDate: '',
      sdmxCode: '',
      updateDate: '',
      componentLoading: true,
      noData: false,
    };
  },
  methods: {
    async getMetaData(idMetaData) {
      const { data } = await Api.getMetaData(idMetaData);
      try {
        this.createDate = this.dateFormating(data.meta_data[0].created_at);
        this.updateDate = this.dateFormating(data.meta_data[0].updated_at);
        this.sdmxCode = data.code;
        this.metaInformatinData = data.meta_data.map((item) => {
          const { name, id, value } = item;
          return {
            name,
            link: /(https?:\/\/[^\s]+)/g.test(value),
            id,
            value,
          };
        });
        this.noData = false;
      } catch (e) {
        this.metaInformatinData = [];
        this.noData = true;
      } finally {
        this.componentLoading = false;
      }
    },
    dateFormating(date) {
      const currentDate = new Date(date);
      const dd = String(currentDate.getDate()).padStart(2, '0');
      const mm = String(currentDate.getMonth()+1).padStart(2, '0');
      const yyyy = currentDate.getFullYear();
      return `${dd}-${mm}-${yyyy}`;
    },
    changecomponentValue(id) {
      this.componentLoading = true;
      this.getMetaData(id);
    },
  },
  mounted() {
    this.$emit('ready');
  },
};
</script>

<style src="./MetaDataComponent.scss" lang="scss">
</style>
