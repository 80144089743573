<template>
  <div>
    <div class="loading-component-bar" v-show="componentLoading">
      <span class="icon icon-loader"></span>
    </div>
    <div v-show="!componentLoading" class="custom-wrapper">
      <div class="custom-wrapper-head">


      <div style="display: flex;
              justify-content: space-between;
              width: 1200px;">
            <h2 id="custom-title">{{ $t("castration") }}</h2>
            <AttentionHisoblash />
          </div>
        





        <v-form class="custom-form" ref="form">
          <div class="custom-item">
            <v-select
              outlined
              hide-details
              :items="items"
              item-text="name"
              item-value="id"
              :placeholder="$t('selectRoute')"
              disabled
              v-model="postObject.firstItem"
            >
            </v-select>
          </div>
          <div class="custom-item">
            <p>{{ $t('operation') }}</p>
            <v-select
              outlined
              hide-details
              :items="operations"
              item-text="name"
              item-value="value"
              :placeholder="$t('selectOperation')"
              @change="checkForm"
              v-model="postObject.operator"
            >
            </v-select>
          </div>
          <div class="custom-item">
            <v-autocomplete
              outlined
              hide-details
              :items="thirdLevelElements"
              item-text="name"
              item-value="id"
              :placeholder="$t('selectRoute')"
              v-model="postObject.secondItem"
              :search-input.sync="search"
              @change="checkForm"
            >
              <template v-slot:append-item>
                <div v-intersect="endIntersect" />
              </template>
            </v-autocomplete>
          </div>
          <div class="custom-item">
            <label class="checkbox-wrapper"
              >{{ $t("convertPercentagesFractions") }}
              <input type="checkbox" v-model="percentage" @change="checkForm" />
              <span class="checkmark"></span>
            </label>
          </div>
        </v-form>
      </div>
      <div
        v-show="!tableLoaded && !noData"
        class="custom-wrapper-table-wrapper"
        v-if="dataTable.length > 0"
      >
        <div>
          <table
            :class="[
              'custom-wrapper-table',
              postObject.operator == 'mul' ? 'mul-table' : 'table',
            ]"
          >
            <thead>
              <tr>
                <th>{{ $t("regions") }}</th>
                <th v-for="item in dataTableYears" :key="item.title">
                  <span>{{ item.title }}</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in dataTable" :key="item.title">
                <td>
                  <p>{{ item.title }}</p>
                </td>
                <td v-for="count in item.counter" :key="count.id">
                  <span>{{ count.value }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="tableLoaded" class="custom-wrapper-table-wrapper-loader">
        <span class="icon icon-loader"></span>
      </div>
      <div class="no-data" v-if="noData">
        {{ $t("informationNotAvailable") }}
      </div>
    </div>
  </div>
</template>

<script>
import Api from '@/api/Api';
import debounce from '../debounce/debounce';
import AttentionHisoblash from '@/components/AttentionHisoblash';

export default {
  name: 'CustomData',
  components: {
    AttentionHisoblash,
  },
  props: {
    items: {
      type: Array,
    },
  },
  data() {
    return {
      operations: [
        {
          name: this.$t('being'),
          value: 'div',
        },
        {
          name: this.$t('increase'),
          value: 'mul',
        },
        {
          name: this.$t('subtraction'),
          value: 'sub',
        },
        {
          name: this.$t('addition'),
          value: 'add',
        },
      ],
      search: null,
      tableLoaded: false,
      postObject: {
        firstItem: null,
        operator: null,
        secondItem: null,
      },
      percentage: null,
      dataTableYears: [],
      rules: {
        required: (v) => !!v,
      },
      dataTable: [],
      paginationObject: {
        page: 1,
        next: null,
        size: 20,
      },
      thirdLevelElements: [],
      currentId: 0,
      currentLang: localStorage.getItem('language')
        ? localStorage.getItem('language')
        : 'uz',
      noData: false,
      componentLoading: true,
    };
  },
  methods: {
    changecomponentValue(id) {
      this.componentLoading = true;
      this.postObject.firstItem = parseInt(id, 10);
      this.getThirdLevelItems();
      this.currentId = id;
    },
    async getThirdLevelItems(
      params = { classifier: JSON.parse(localStorage.getItem('classifier')).id, size: 3500 },
    ) {
      const { data } = await Api.getThirdLevelItems(params);
      this.setPaginationObject(data);
      const { results } = data;
      this.thirdLevelElements = this.thirdLevelElements.concat(results);
      this.componentLoading = false;
    },
    setPaginationObject({ next }) {
      this.paginationObject.next = next;
    },
    endIntersect(entries, observer, isIntersecting) {
      if (this.search !== null) return;
      if (isIntersecting) {
        if (this.paginationObject.next === null) return;
        this.paginationObject.page += 1;
        const params = {
          page: this.paginationObject.page,
          size: this.paginationObject.size,
          classifier: JSON.parse(localStorage.getItem('classifier')).id,
        };
        this.getThirdLevelItems(params);
      }
    },
    checkForm() {
      if (Object.values(this.postObject).some((value) => value === null)) { return; }
      const params = {
        sdmx_id: `${this.postObject.secondItem}`,
        operator: this.postObject.operator,
        percent: this.percentage === true ? 1 : 0,
      };
      this.getTableData(params);
    },
    async getTableData(params) {
      this.tableLoaded = true;
      this.noData = false;
      const { data } = await Api.getCastomData(this.currentId, params);
      if (data) {
        this.setUpTable(data);
      } else {
        this.noData = true;
        this.tableLoaded = false;
      }
    },
    setUpTable(data) {
      this.dataTableYears = data[0].data.map((item) => ({
        title: Object.keys(item).shift(),
      }));
      this.dataTable = data.map((item) => {
        const { id, name, data: counts } = item;
        return {
          id,
          title: item[`name_${this.currentLang}`]
            ? item[`name_${this.currentLang}`]
            : name,
          counter: counts.map((count) => ({
            id: this.idGenerator(),
            value: Number(Object.values(count).shift())
              .toString()
              .replace('.', ','),
          })),
        };
      });
      this.tableLoaded = false;
    },
    idGenerator() {
      return `${Math.random().toString(36).substr(2, 9)}`;
    },
  },
  watch: {
    async search(val) {
      if (val === null) return;
      // const hasItem = this.thirdLevelElements
      //   .map(({ name }) => name)
      //   .some((item) => item.toLowerCase().includes(val.toLowerCase()));
      // if (hasItem) return;
      const params = {
        search: val,
        size: this.paginationObject.size,
        classifier: JSON.parse(localStorage.getItem('classifier')).id,
      };
      this.searchDebounce(params);
    },
  },
  mounted() {
    this.$emit('ready');
    this.searchDebounce = debounce(async (params) => {
      const { data } = await Api.getThirdLevelItems(params);
      const { results } = data;
      this.thirdLevelElements = this.thirdLevelElements.concat(results);
    }, 500);
  },
};
</script>

<style lang="scss" src="./CustomData.scss"></style>
