<template>
  <div>
    <div class="loading-component-bar" v-show="componentLoading">
      <span class="icon icon-loader"></span>
    </div>
    <div v-show="!componentLoading">
      <div v-if="!noData" class="data-table-wrapper">
        <div class="data-table-wrapper-head">
          <div class="data-table-wrapper-select">
            <v-autocomplete
              outlined
              :items="selectList"
              item-text="name"
              item-value="id"
              :placeholder="$t('filtertable')"
              hide-details
              @change="setClassifier"
              @click:clear="setClassifier(null)"
              clearable
            >
            </v-autocomplete>
          </div>

          <div>
              <AttentionTable />
          </div>


          <div class="data-table-wrapper-buttons">
            <button
              @click="downloadFile('xlsx')"
              type="button"
              class="download-btn"
            >
              Excel
            </button>
            <button
              @click="downloadFile('pdf')"
              type="button"
              class="download-btn"
            >
              PDF
            </button>
            <button
              @click="downloadFile('csv')"
              type="button"
              class="download-btn"
            >
              CSV
            </button>
            <button
              @click="downloadFile('json')"
              type="button"
              class="download-btn"
            >
              JSON (API)
            </button>
            <button
              @click="downloadFile('xml')"
              type="button"
              class="download-btn"
            >
              XML (API)
            </button>
          </div>
        </div>
        <div class="data-table-wrapper-table-wrapper">
          <table class="data-table-wrapper-table">
            <thead>
              <tr>
                <th>{{ currentclassifier.name }}</th>
                <th v-for="item in dataTableYears" :key="item.title">
                  <span>{{ item.title }}</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in dataTable" :key="item.id">
                <td>
                  <p>{{ item.title }}</p>
                </td>
                <td v-for="counter in item.counter" :key="counter.id">
                  <span>{{ counter.value }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="no-data" v-if="noData">
        {{ $t("informationNotAvailable") }}
      </div>
    </div>
  </div>
</template>

<script>
import Api from '@/api/Api';
import AttentionTable from '@/components/AttentionTable'

export default {
  name: 'DataTableComponent',
  components: {
   AttentionTable
  },
  data() {
    return {
      dataTableYears: [],
      selectList: [],
      dataTable: [],
      code: null,
      currentclassifier: { id: 1, name: 'HUDUD' },
      noData: false,
      currentLang: localStorage.getItem('language')
        ? localStorage.getItem('language')
        : 'uz',
      componentLoading: true,
    };
  },
  methods: {
    async getTableData(id, params = {}) {
      const { data } = await Api.getTableData(id, params);
      if (data.length > 0) {
        this.prepareData(data);
        this.noData = false;
      } else {
        this.noData = true;
      }
      this.componentLoading = false;
    },
    idGenerator() {
      return `${Math.random().toString(36).substr(2, 9)}`;
    },
    setClassifier(id) {
      const params = {
        classifier_item: id,
      };
      this.getTableData(this.code, params);
    },
    prepareData(data) {
      this.dataTableYears = data[0].data.map((item) => ({
        title: Object.keys(item).shift(),
      }));
      this.dataTable = data.map((items) => {
        const { id: parentId, name, data: counts } = items;
        return {
          id: parentId,
          title: items[`name_${this.currentLang}`]
            ? items[`name_${this.currentLang}`]
            : name,
          counter: counts.map((count) => ({
            id: this.idGenerator(),
            value: Number(Object.values(count).shift())
              .toString()
              .replace('.', ','),
          })),
        };
      });
      if ('children' in data[0]) {
        const childrenArray = data[0].children.map((item) => {
          const { id: parentId, name, data: counts } = item;
          return {
            id: parentId,
            title: item[`name_${this.currentLang}`]
              ? item[`name_${this.currentLang}`]
              : name,
            counter: counts.map((count) => ({
              id: this.idGenerator(),
              value: Number(Object.values(count).shift())
                .toString()
                .replace('.', ','),
            })),
          };
        });
        this.dataTable = [...this.dataTable, ...childrenArray];
      }
      if (this.selectList.length > 0) return;
      this.selectList = data.map((region) => {
        const { id, name } = region;
        return {
          name: region[`name_${this.currentLang}`] || name,
          id,
        };
      });
      this.componentLoading = false;
    },
    changecomponentValue(id) {
      this.componentLoading = true;
      this.code = id;
      this.getTableData(id);
      this.currentclassifier = localStorage.getItem('classifier')
        ? JSON.parse(localStorage.getItem('classifier'))
        : { id: 1, name: 'HUDUD' };
    },
    async downloadFile(type) {
      const params = {
        download_format: type,
      };
      const { data } = await Api.downloadTableFile(this.code, params);
      const { file } = data;
      const link = document.createElement('a');
      link.href = file;
      link.target = '_blank';
      link.click();
      link.remove();
    },
  },
  mounted() {
    this.$emit('ready');
  },
};
</script>

<style src="./DataTableComponent.scss" lang="scss"></style>
