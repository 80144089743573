<template>
  <div>
    <div class="loading-component-bar" v-show="componentLoading">
      <span class="icon icon-loader"></span>
    </div>
    <div v-show="!componentLoading">
      <div class="bar-year">
        <div class="years-wrapper w-100">
          <h2 id="years-title">{{ $t("chooseYear") }}</h2>
          <div class="years-actions">
            <div class="years-btns-play">
              <button @click="setPlay()" :class="{ active: play }">
                <span class="icon icon-play"></span>
              </button>
            </div>
            <div
              class="years-btns"
              :class="{ disable: play }"
              v-bind:style="{ height: buttonShowline * 66 + 'px' }"
            >
              <button
                v-for="year in years"
                :key="year.value"
                @click="setYear(year.value)"
                :class="{ active: year.active }"
              >
                {{ year.name }}
              </button>
            </div>
          </div>
          <button
            v-if="buttonShowline * 9 < years.length"
            @click="buttonShowline += 1"
            class="years-add-button"
          >
            {{ $t("seeMore") }}
          </button>
        </div>
      </div>
      <div class="highcharts">
        <div class="highcharts-head">
          <h3>{{ title }}</h3>
          <div class="highcharts-head-right">
            <button @click="downloadJpg">{{ $t("downloadPng") }}</button>
            <div class="highcharts-head-select">
              <v-select
                item-text="name"
                item-value="id"
                :items="regionsSelectList"
                :label="$t('zoneSelection')"
                @change="createRegionChart($event, 'select')"
                outlined
              ></v-select>
            </div>
          </div>
        </div>
        <div class="highcharts-wrapper">
          <div id="highcharts"></div>
        </div>
      </div>
      <div class="bar-region" v-if="regionshow">
        <h3>{{ $t("regions") }}</h3>
        <div class="region-list">
          <div
            v-for="item in regionsList"
            :key="item.title"
            @click="createRegionChart(item.value.id)"
          >
            <h5>{{ item.title }}</h5>
            <span>{{ item.value.count }}</span>
          </div>
        </div>
      </div>
      <div v-show="regionHigChart" class="highcharts">
        <div v-show="!noRegionData" class="highcharts-region-head">
          <h3>{{ regionTitle ? regionTitle.name : "null" }}</h3>
          <button @click="downloadRegionJpg">{{ $t("downloadPng") }}</button>
        </div>
        <div v-show="noRegionData">{{ $t("informationNotAvailable") }}</div>
        <div v-show="!noRegionData" id="highchartsRegion"></div>
      </div>
      <div class="bar-region" v-if="regionsShow">
        <h3>{{ $t("districts") }}</h3>
        <div class="region-list">
          <div v-for="item in regionHigchartMiniMenus" :key="item.title">
            <h5>{{ item.title }}</h5>
            <span>{{ item.value.count }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Highcharts from 'highcharts';
import Api from '@/api/Api';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import { EventBus } from './event-bus';
/* eslint-disable no-alert */

export default {
  name: 'BarDataComponent',
  data() {
    return {
      highcharts: '',
      highchartsRegion: '',
      currentYear: new Date().getFullYear(),
      currentIndex: 0,
      years: [],
      regionshow: false,
      play: false,
      time: null,
      highChartsData: [],
      currentId: 0,
      title: 'Aholi soni',
      buttonShowline: 2,
      regionsList: [],
      regionsSelectList: [],
      regionHigChart: false,
      regionHigchartMiniMenus: [],
      regionsShow: false,
      noRegionData: false,
      regionTitle: null,
      currentLang: localStorage.getItem('language')
        ? localStorage.getItem('language')
        : 'uz',
      componentLoading: true,
    };
  },
  methods: {
    setYear(year) {
      this.years.forEach((el) => {
        const isEqual = el.value === year;
        if (isEqual) {
          // eslint-disable-next-line no-param-reassign
          el.active = true;
          this.currentYear = year;
          this.getGistogramData(this.currentId);
        } else {
          // eslint-disable-next-line no-param-reassign
          el.active = false;
        }
      });
    },
    setYears() {
      this.currentIndex = this.years.findIndex(
        (el) => String(el.value) === String(this.currentYear),
      );
      this.currentIndex += 1;
      if (this.currentIndex >= this.years.length) {
        this.currentIndex = 0;
        this.currentYear = this.years[this.currentIndex].value;
        this.setYear(this.currentYear);
      } else {
        this.currentYear = this.years[this.currentIndex].value;
        this.setYear(this.currentYear);
      }
      this.getData();
    },
    async setPlay() {
      this.play = !this.play;
      if (this.play) {
        this.setYears();
      } else {
        clearTimeout(this.timer);
      }
    },
    downloadJpg() {
      const svg = document.querySelector('.highcharts-wrapper');
      if (svg == null) {
        alert(this.$t('pleaseWaitForDiagramFullyLoad'));
      } else {
        const title = document.createElement('h2');
        title.textContent = this.title;
        title.style.textAlign = 'center';
        const toolBar = document.querySelectorAll('.apexcharts-toolbar');
        for (let i = 0; i < toolBar.length; i += 1) {
          toolBar[i].style.display = 'none';
        }
        svg.prepend(title);
        html2canvas(svg).then((canvas) => {
          canvas.toBlob((blob) => {
            saveAs(blob, `${this.title}Image.jpg`);
          });
        });
        title.remove();
        for (let i = 0; i < toolBar.length; i += 1) {
          toolBar[i].style.display = 'flex';
        }
      }
    },
    downloadRegionJpg() {
      const svg = document.querySelector('#highchartsRegion');
      if (svg == null) {
        alert(this.$t('pleaseWaitForDiagramFullyLoad'));
      } else {
        const title = document.createElement('h2');
        title.textContent = this.regionTitle ? this.regionTitle.name : null;
        title.style.textAlign = 'center';
        const toolBar = document.querySelectorAll('.apexcharts-toolbar');
        for (let i = 0; i < toolBar.length; i += 1) {
          toolBar[i].style.display = 'none';
        }
        svg.prepend(title);
        html2canvas(svg).then((canvas) => {
          canvas.toBlob((blob) => {
            saveAs(
              blob,
              `${this.regionTitle ? this.regionTitle.name : null}Image.jpg`,
            );
          });
        });
        title.remove();
        for (let i = 0; i < toolBar.length; i += 1) {
          toolBar[i].style.display = 'flex';
        }
      }
    },
    async getData() {
      this.timer = setTimeout(this.setYears, 5000);
    },
    async createRegionChart(id, event) {
      this.regionHigChart = true;
      if (event === 'select') {
        this.regionshow = false;
      }
      const { data } = await Api.getGistogramRegionsData(this.currentId, id, {
        year: this.currentYear,
      });
      const newHighChartsData = Object.keys(data).map((key) => {
        const childern = Object.keys(data[key]).map((keys) => keys);
        return {
          key,
          y: childern.length,
          tooltipHeader: key,
        };
      });
      this.highchartsRegion = data;
      const title = this.regionsSelectList.find((item) => item.id === id);
      this.regionTitle = title;
      this.createRegionHighcharts(newHighChartsData);
    },
    async getGistogramData(id) {
      const { data } = await Api.getGistogramData(id, {
        year: this.currentYear,
      });
      const {
        data: { regions },
      } = await Api.getRegionsList(id);
      this.regionsSelectList = regions.map((item) => ({
        id: item.id,
        name: item[`name_${this.currentLang}`]
          ? item[`name_${this.currentLang}`]
          : item.name_uz,
      }));
      const newHighChartsData = Object.keys(data).map((key) => {
        const childern = Object.keys(data[key]).map((keys) => keys);
        return {
          key,
          y: childern.length,
          tooltipHeader: key,
        };
      });
      this.highChartsData = data;
      this.createHighcharts(newHighChartsData);
      this.regionshow = false;
      this.componentLoading = false;
    },
    createHighcharts(HighchartsData) {
      const region = this.$t('regions');
      this.highcharts = Highcharts.chart({
        chart: {
          type: 'column',
          renderTo: 'highcharts',
        },
        title: {
          text: '',
        },
        xAxis: {
          type: 'category',
          labels: {
            rotation: 0,
            style: {
              fontSize: '0.813rem',
              fontFamily: 'Verdana, sans-serif',
            },
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: '',
          },
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          column: {
            color: '#3F8DFD',
            borderRadius: '3px',
            tooltip: {
              headerFormat: '',
              pointFormatter() {
                return `${this.tooltipHeader} <br/> ${this.y} ${region}`;
              },
            },
          },
          series: {
            cursor: 'pointer',
            point: {
              events: {
                click(e) {
                  EventBus.$emit('chartClicked', e.point);
                },
              },
            },
          },
        },
        series: [
          {
            name: 'Population',
            data: HighchartsData,
            dataLabels: {
              enabled: true,
              format: '{point.key}',
            },
          },
        ],
      });
    },
    createRegionHighcharts(HighchartsData) {
      this.noRegionData = false;
      if (HighchartsData.length === 0) {
        this.noRegionData = true;
      }
      this.regionsShow = false;
      this.highcharts = Highcharts.chart({
        chart: {
          type: 'column',
          renderTo: 'highchartsRegion',
        },
        title: {
          text: '',
        },
        xAxis: {
          type: 'category',
          labels: {
            rotation: 0,
            style: {
              fontSize: '0.813rem',
              fontFamily: 'Verdana, sans-serif',
            },
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: '',
          },
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          column: {
            color: '#3F8DFD',
            borderRadius: '3px',

            tooltip: {
              headerFormat: '',
              pointFormatter() {
                return `${this.tooltipHeader} <br/> ${this.y} Tumanlar`;
              },
            },
          },
          series: {
            cursor: 'pointer',
            point: {
              events: {
                click(e) {
                  EventBus.$emit('chartRegionBarClicked', e.point);
                },
              },
            },
          },
        },
        series: [
          {
            name: 'Population',
            data: HighchartsData,
            dataLabels: {
              enabled: true,
              format: '{point.key}',
            },
          },
        ],
      });
    },
    async getYearsData(id) {
      this.currentId = id;
      const { data } = await Api.getYearsData(id);
      this.title = data.name;
      this.years = data.years.map((year) => ({
        name: year,
        value: year,
        active: false,
      }));
      this.buttonShowline = this.years.length > 9 ? 2 : 1;
      this.setYear(this.years[0].value);
    },
    changecomponentValue(id) {
      if (this.currentId !== id) {
        this.componentLoading = true;
        this.getYearsData(id);
      }
    },
    highchartBarMiniOpenMenus(event) {
      this.regionsList = Object.keys(this.highChartsData[event.key]).map(
        (key) => ({
          title: key,
          value: this.highChartsData[event.key][key],
        }),
      );
      this.regionshow = true;
    },
    regionHigChartMiniMenus(event) {
      this.regionHigchartMiniMenus = Object.keys(
        this.highchartsRegion[event.key],
      ).map((key) => ({
        title: key,
        value: this.highchartsRegion[event.key][key],
      }));
      this.regionsShow = true;
    },
  },
  mounted() {
    this.$emit('ready');
    EventBus.$on('chartClicked', (point) => {
      this.highchartBarMiniOpenMenus(point);
    });
    EventBus.$on('chartRegionBarClicked', (point) => {
      this.regionHigChartMiniMenus(point);
    });
  },
};
</script>

<style lang="scss" src="./BarDataComponent.scss"></style>
