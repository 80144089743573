<template>
  <div>
    <div class="reyting-dialog-wrapper-dialog-head">
      <div>
        <h2>{{ title }}</h2>

        <div style="margin-right: 15%;">
              <AttentionReytingBar />
        </div>

        <div>
          <button @click="downloadSvg">{{ $t("downloadPng") }}</button>
          <div class="reyting-dialog-wrapper-dialog-head-select">
            <v-select
              @change="changeRegion"
              item-text="name"
              item-value="name"
              :items="regionsList"
              :label="$t('zoneSelection')"
              outlined
            ></v-select>
          </div>
        </div>
      </div>
      <h3>{{ Year }}</h3>
    </div>
    <div class="reyting-body">
      <canvas id="myChart"></canvas>
      <div class="chart-js-labels">
        <button
          :class="{ noActive: removeItems.find((items) => items == item.value) }"
          v-for="item in regionsList"
          :key="item.value"
          @click="changeChart(item.value)"
        >
          {{ item.name }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js';
import Vue from 'vue';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import AttentionReytingBar from '@/components/AttentionReytingBar';

Vue.prototype.$Chart = Chart;

export default {
  name: 'basic',
  components: {
    AttentionReytingBar
  },
  props: {
    backgroundColor: {
      type: [Array, Object, Number, String],
      default: () => [
        'rgba(192, 199, 212, 1)',
        'rgba(192, 199, 212, 1)',
        'rgba(192, 199, 212, 1)',
        'rgba(192, 199, 212, 1)',
        'rgba(192, 199, 212, 1)',
        'rgba(192, 199, 212, 1)',
        'rgba(192, 199, 212, 1)',
        'rgba(192, 199, 212, 1)',
        'rgba(192, 199, 212, 1)',
        'rgba(192, 199, 212, 1)',
        'rgba(192, 199, 212, 1)',
        'rgba(192, 199, 212, 1)',
        'rgba(192, 199, 212, 1)',
        'rgba(192, 199, 212, 1)',
        'rgba(192, 199, 212, 1)',
      ],
    },
    reytingTitle: {
      type: String,
    },
    chartBarYear: {
      type: [Number, String],
      default: 2013,
    },
  },
  data() {
    return {
      data: [],
      ChartBar: '',
      regionsList: [],
      removeItems: [],
      currentLang: localStorage.getItem('language') ? localStorage.getItem('language') : 'uz',
    };
  },
  methods: {
    changeChart(id) {
      const findId = this.removeItems.findIndex((item) => item === id);
      if (findId === -1) {
        this.removeItems.push(id);
      } else {
        this.removeItems.splice(findId, 1);
      }
      this.$emit('refreshChart');
    },
    downloadSvg() {
      const svg = document.querySelector('.reyting-body');
      if (svg == null) {
        alert(this.$t('pleaseWaitForDiagramFullyLoad'));
      } else {
        const title = document.createElement('h2');
        title.textContent = this.title;
        title.style.textAlign = 'center';
        svg.prepend(title);
        html2canvas(svg).then((canvas) => {
          canvas.toBlob((blob) => {
            saveAs(blob, `${this.title}Image.jpg`);
          });
        });
        title.remove();
      }
    },
    changeRegion(event) {
      for (let i = 0; i < this.backgroundColor.length; i += 1) {
        this.backgroundColor[i] = 'rgba(63, 141, 253, 1)';
      }
      this.backgroundColor[this.data.findIndex((e) => e.id === 1)] = 'rgba(63,141,253,1)';
      this.backgroundColor[this.data.findIndex((e) => e.text === event)] = 'rgba(44, 177, 109, 1)';
      this.ChartBar.update();
      this.$emit('clickBar', event);
    },
    createCharBar(datas, regions) {
      if (regions) {
        this.regionsList = regions.map((item) => ({
          name: item[`name_${this.currentLang}`] ? item[`name_${this.currentLang}`] : item.name,
          value: item.id,
        }));
      }
      if (this.ChartBar) {
        this.ChartBar.destroy();
      }
      for (let i = 0; i < this.backgroundColor.length; i += 1) {
        this.backgroundColor[i] = 'rgba(63, 141, 253, 1)';
      }
      this.removeItems.forEach((item) => {
        datas.splice(
          datas.findIndex((items) => items.id === item),
          1,
        );
      });
      const data = this.NewData(datas);
      this.backgroundColor[
        data.data.labels.findIndex((item) => item === this.$t('republicofUzbekistan'))
      ] = 'rgba(63, 141, 253, 1)';
      this.data.sort((a, b) => b.value - a.value);
      const ctx = document.getElementById('myChart');
      this.ChartBar = new this.$Chart(ctx, data);
    },
    NewData(data) {
      this.data = data;
      data.sort((a, b) => b.value - a.value);
      const labels = data.map((item) => item.text);
      const values = data.map((item) => item.value);
      return {
        type: 'bar',
        data: {
          labels,
          datasets: [
            {
              label: this.title,
              data: values,
              id: values,
              borderWidth: 1,
              hoverBackgroundColor: 'rgba(44, 177, 109, 1)',
              backgroundColor: this.backgroundColor,
            },
          ],
        },
        options: {
          plugins: {
            colorschemes: {
              scheme: 'brewer.Pastel1-3',
            },
          },
          legend: {
            position: 'bottom',
            display: false, // This will do the task
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  fontColor: 'rgba(86, 102, 131, 1)',
                  autoSkip: false,
                  maxRotation: 90,
                  minRotation: 90,
                },
              },
            ],
          },
          onClick: this.graphClickEvent,
        },
      };
    },
    graphClickEvent(event, array) {
      if (array[0]) {
        // eslint-disable-next-line no-underscore-dangle
        const id = array[0]._view.label;
        this.$emit('clickBar', id);
        this.changeRegion(id);
      }
    },
  },
  computed: {
    title() {
      return this.reytingTitle;
    },
    Year() {
      return this.chartBarYear;
    },
  },
};
</script>
