<template>
  <div class="report">
    <public-header />
    <main>
      <div class="report-container">
        <div class="report-left">
          <label class="search-input">
            <input
              type="text"
              :placeholder="this.$t('search')"
              @input="searchMenu"
              v-model="search"
            />
            <span class="icon icon-search"></span>
          </label>
          <div
            v-show="!searchByInput"
            class="sidebar-level-one"
            v-for="item in sidebar"
            :key="item.id"
            @click="item.open = !item.open"
            :class="[{ active: item.open }, { selected: item.active }]"
          >
            <div class="level-one-header">
              <p>
                {{ item.name }}
              </p>
              <div class="arrow-wrapper">
                <span class="icon icon-sidebar-arrow"></span>
              </div>
            </div>
            <div v-if="item.open" class="level-two-wrapper">
              <div
                class="level-two"
                v-for="child in item.children"
                :key="child.id"
                @click.stop="child.open = !child.open"
                :class="[{ active: child.open }]"
              >
                <div class="level-two-header">
                  <p>
                    {{ child.name }}
                  </p>
                  <div class="arrow-wrapper">
                    <span class="icon icon-sidebar-arrow-1"></span>
                  </div>
                </div>
                <div
                  class="level-two ml-5"
                  v-show="child.open"
                  v-for="childTwu in child.children"
                  :key="childTwu.id"
                  @click.stop="childTwu.open = !childTwu.open"
                  :class="[{ active: childTwu.open }]"
                  >
                  <div class="level-two-header">
                    <p>
                      {{ childTwu.name }}
                    </p>
                    <div class="arrow-wrapper">
                      <span class="icon icon-sidebar-arrow-1"></span>
                    </div>
                  </div>
                  <div class="level-three" v-if="childTwu.open">
                    <span
                      v-for="category in childTwu.children"
                      v-show="childTwu.children !== 0"
                      :key="category.id"
                      @click.stop="setCode(category.id, childTwu.id)"
                      :class="{ active: category.active }"
                    >
                      {{ category.name }}
                    </span>
                    <span v-show="childTwu.children == 0" @click.stop>
                      {{ $t('thereIsNoInformation') }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-show="searchByInput">
            <ul>
              <li v-for="item in menusSearchResults" :key="item.id">
                <span class="search-results" @click.stop="setCode(item.id)">
                  {{ item.name }}
                </span>
              </li>
              <li v-if="menusSearchResults.length == 0">
                {{ $t("informationNotAvailable") }}
              </li>
            </ul>
          </div>
        </div>
        <div class="report-right">
          <div class="old-links">
            <div v-for="item in items" :key="item.text">
              <router-link v-if="item.to" :to="item.to">
                {{ item.text }}
              </router-link>
              <p v-else>
                {{ item.text }}
              </p>
              <span class="hover-text" v-if="item.text.split(' ').length > 4">{{
                item.text
              }}</span>
              <span class="icon icon-arrow-next"></span>
            </div>
          </div>
          <h2>{{ menuTitle }}</h2>
          <div class="reports">
            <div class="reports-type">
              <button
                v-for="button in buttonsList"
                :key="button.type"
                @click="setComponent(button.type)"
                :class="[
                  { active: button.active },
                  { hideButton: !button.show },
                ]"
              >
                {{ button.name }}
              </button>
            </div>
          </div>
          <keep-alive>
            <component
              :ref="currentComponent"
              :is="currentComponent"
              @ready="componentReady"
              :items="thirdLevelElements"
            >
            </component>
          </keep-alive>
        </div>
      </div>
    </main>
    <div class="site-loader" v-if="siteLoading">
      <img src="@/assets/icons/Dark_logo.svg" alt="Site logo" />
    </div>
    <home-footer />
  </div>
</template>

<script>
import PublicHeader from '@/components/PublicHeader';
import HomeFooter from '@/components/HomeFooter';
import Api from '@/api/Api';
import TableData from './ConstructorComponents/DataTableComponent';
import CompareData from './ConstructorComponents/MapCompare';
import MapData from './ConstructorComponents/MapPopulation';
import BarData from './ConstructorComponents/BarDataComponent';
import RateData from './ConstructorComponents/ReytingdiagramaComponent';
import LineData from './ConstructorComponents/AreaDataComponent';
import CustomData from './ConstructorComponents/CustomData';
import RelationshipData from './ConstructorComponents/RelationshipData';
import MetaData from './ConstructorComponents/MetaDataComponent';
import debounce from './ConstructorComponents/debounce/debounce';
/* eslint-disable no-param-reassign */

export default {
  name: 'ReportsByFiled',
  components: {
    PublicHeader,
    HomeFooter,
    TableData,
    CompareData,
    MapData,
    BarData,
    RateData,
    LineData,
    CustomData,
    RelationshipData,
    MetaData,
  },
  data() {
    return {
      thirdLevelElements: [],
      buttonsList: [],
      sidebar: [],
      items: [
        {
          text: this.$t('main'),
          to: {
            path: '/',
          },
        },
        {
          text: this.$t('officialStatisticsData'),
        },
        {
          text: 'DEMOGRAFIK VA IJTIMOIY STATISTIKA',
        },
        {
          text: 'Aholi va migratsiya',
        },
        {
          text: 'Aholi va migratsiya sdsd',
        },
        {
          text: 'Aholi soni',
        },
      ],
      menusSearchResults: [],
      searchByInput: false,
      siteLoading: true,
      menuTitle: null,
      currentComponent: this.$route.params.type,
      currentCode: this.$route.params.menu,
      search: null,
      currentLang: localStorage.getItem('language')
        ? localStorage.getItem('language')
        : 'uz',
    };
  },
  methods: {
    setActive(id, sidebar) {
      sidebar.forEach((el) => {
        if (parseInt(el.id, 10) === parseInt(id, 10)) {
          // eslint-disable-next-line no-param-reassign
          el.open = !el.open;
        } else {
          // eslint-disable-next-line no-param-reassign
          el.open = false;
        }
      });
    },
    async setCode(code) {
      this.siteLoading = true;
      const getName = (type) => {
        switch (type) {
          case 'metadata':
            return {
              name: this.$t('metadata'),
              positon: 0,
            };
          case 'table':
            return {
              name: this.$t('table'),
              positon: 1,
            };
          case 'rating':
            return {
              name: this.$t('rating'),
              positon: 2,
            };
          case 'gistogram':
            return {
              name: this.$t('histogramme'),
              positon: 3,
            };
          case 'graphics':
            return {
              name: this.$t('graphic'),
              positon: 5,
            };
          case 'cartogram':
            return {
              name: this.$t('cartographic'),
              positon: 4,
            };
          case 'compare_regions':
            return {
              name: this.$t('comparison'),
              positon: 6,
            };
          case 'comparative_ratio':
            return {
              name: this.$t('relationship'),
              positon: 7,
            };
          case 'customization':
            return {
              name: this.$t('castration'),
              positon: 8,
            };
          default:
            return '';
        }
      };
      const getButtonType = (type) => {
        switch (type) {
          case 'metadata':
            return 'meta-data';
          case 'table':
            return 'table-data';
          case 'rating':
            return 'rate-data';
          case 'gistogram':
            return 'bar-data';
          case 'graphics':
            return 'line-data';
          case 'cartogram':
            return 'map-data';
          case 'compare_regions':
            return 'compare-data';
          case 'comparative_ratio':
            return 'relationship-data';
          case 'customization':
            return 'custom-data';
          default:
            return null;
        }
      };
      let params = {};
      // eslint-disable-next-line
      if (code == 0) {
        params = {
          first: true,
        };
      }
      const { data } = await Api.getOneOpenIformation(code, params);
      this.currentCode = data.id;
      this.sidebar.forEach((item) => {
        const activebigbar = [];

        item.children.forEach((itemTwo) => {
          if (itemTwo.children) {
            const childrenActives = [];
            itemTwo.children.forEach((itemThree) => {
              const childActivesThree = [];
              itemThree.children.forEach((itemsFour) => {
                if (
                  parseInt(itemsFour.id, 10) === parseInt(this.currentCode, 10)
                ) {
                  itemThree.open = true;
                  itemsFour.active = true;
                  childrenActives.push(true);
                  childActivesThree.push(true);
                  itemTwo.open = true;
                  item.open = true;
                  item.active = true;
                  this.thirdLevelElements = itemThree.children.map(
                    ({ id: itemId, name }) => ({
                      id: itemId,
                      name,
                    }),
                  );
                  this.items[2].text = item.name;
                  this.items[3].text = itemTwo.name;
                  this.items[4].text = itemThree.name;
                  this.items[5].text = itemsFour.name;
                  this.menuTitle = itemsFour.name;
                  // eslint-disable-next-line eqeqeq
                  if (code == 0) {
                  // eslint-disable-next-line no-param-reassign
                    code = this.currentCode;
                  }
                  this.currentCode = code;
                  window.history.pushState(
                    null,
                    null,
                    `/reports-filed/${code}/${this.currentComponent}`,
                  );
                  window.history.replaceState(
                    null,
                    null,
                    `/reports-filed/${code}/${this.currentComponent}`,
                  );
                } else {
                  childrenActives.push(false);
                  // eslint-disable-next-line no-param-reassign
                  itemsFour.active = false;
                }
                if (childrenActives.includes(true)) {
                // eslint-disable-next-line no-param-reassign
                  itemTwo.active = true;
                  activebigbar.push(true);
                } else {
                // eslint-disable-next-line no-param-reassign
                  itemTwo.active = false;
                  // eslint-disable-next-line no-param-reassign
                  itemTwo.open = false;
                  activebigbar.push(false);
                }
              });
            });
          }
        });
        if (activebigbar.includes(true)) {
          // eslint-disable-next-line no-param-reassign
          item.active = true;
        } else {
          // eslint-disable-next-line no-param-reassign
          item.active = false;
        }
      });
      const { view_options: buttonOptions } = data;
      this.buttonsList = buttonOptions.map((type) => ({
        name: getName(type).name,
        positon: getName(type).positon,
        type: getButtonType(type),
        show: true,
        active: false,
      }));
      localStorage.setItem('cartogramCount', data.cartogram_count);
      localStorage.setItem('classifier', JSON.stringify(data.classifier));
      this.buttonsList.sort((a, b) => a.positon - b.positon);
      if (
        !this.buttonsList.find((item) => item.type === this.currentComponent)
      ) {
        this.currentComponent = 'meta-data';
      }
      if (this.buttonsList.length === 0) {
        this.buttonsList = [
          {
            name: this.$t('metadata'),
            positon: 0,
            type: 'meta-data',
            show: true,
            active: true,
          },
        ];
      }
      this.searchByInput = false;
      await this.setComponent(this.currentComponent);
      // if (this.$refs[this.currentComponent] !== undefined) {
      //   this.changecomponentValue(code);
      // }
    },
    setComponent(type) {
      this.currentComponent = type;
      this.buttonsList.forEach((button) => {
        if (button.type === type) {
          // eslint-disable-next-line no-param-reassign
          button.active = true;
        } else {
          // eslint-disable-next-line no-param-reassign
          button.active = false;
        }
      });
      window.history.pushState(
        null,
        null,
        `/reports-filed/${this.currentCode}/${type}`,
      );
      window.history.replaceState(
        null,
        null,
        `/reports-filed/${this.currentCode}/${type}`,
      );
      setTimeout(() => {
        this.siteLoading = false;
        this.changecomponentValue(this.currentCode);
      }, 800);
    },
    componentReady() {
      // this.changecomponentValue(this.currentCode);
    },
    changecomponentValue(id) {
      if (!id) {
        this.$refs[this.currentComponent].changecomponentValue(
          this.$route.params.menu,
        );
      } else {
        this.$refs[this.currentComponent].changecomponentValue(id);
      }
    },
    async getMenudata() {
      const { data } = await Api.getOpenInformationDataWeb();
      const capitalizeFirstLetter = (
        [first, ...rest],
        locale = navigator.language,
      ) => first.toLocaleUpperCase(locale) + rest.join('');
      this.sidebar = data.map((levelOne) => {
        const response = {
          id: levelOne.id,
          name: capitalizeFirstLetter(
            levelOne[`name_${this.currentLang}`]
              ? levelOne[`name_${this.currentLang}`].toLowerCase()
              : levelOne.name.toLowerCase(),
          ),
          active: false,
          open: false,
          children: [],
        };
        if (levelOne.children) {
          response.children = levelOne.children.map((levelTwo) => {
            const responseTwo = {
              id: levelTwo.id,
              name: levelTwo[`name_${this.currentLang}`]
                ? levelTwo[`name_${this.currentLang}`]
                : levelTwo.name,
              active: false,
              open: false,
              children: [],
            };
            if (levelTwo.children) {
              responseTwo.children = levelTwo.children.map((levelThree) => {
                const responseFoure = {
                  id: levelThree.id,
                  name: levelThree[`name_${this.currentLang}`]
                    ? levelThree[`name_${this.currentLang}`]
                    : levelThree.name,
                  open: false,
                  children: [],
                  active: false,
                };

                responseFoure.children = levelThree.children.map(
                  (levleFoure) => ({
                    id: levleFoure.id,
                    name: levleFoure[`name_${this.currentLang}`]
                      ? levleFoure[`name_${this.currentLang}`]
                      : levleFoure.name,
                    active: false,
                  }),
                );

                return responseFoure;
              });
            }
            return responseTwo;
          });
        }
        return response;
      });
      const { menu } = this.$route.params;
      this.setCode(menu);
    },
    // eslint-disable-next-line consistent-return
    getPath(object, search) {
      if (parseInt(object.id, 10) === parseInt(search, 10)) return [object.id];
      if (object.children || Array.isArray(object)) {
        const children = Array.isArray(object) ? object : object.children;
        // eslint-disable-next-line no-restricted-syntax
        for (const child of children) {
          const result = this.getPath(child, search);
          if (result) {
            if (object.id) result.unshift(object.id);
            return result;
          }
        }
      }
    },
    findParents(arr = []) {
      arr.forEach((id) => {
        const [firstParent, secondParent] = this.getPath(this.sidebar, id);
        let firstParentItem = null;
        this.sidebar.forEach((item) => {
          if (item.id === firstParent) {
            // eslint-disable-next-line no-param-reassign
            item.open = true;
            // eslint-disable-next-line no-param-reassign
            item.active = true;
            this.firstParentItem = firstParentItem;
          }
        });
        firstParentItem = this.sidebar.find(
          (element) => element.id === firstParent,
        );
        firstParentItem.children.forEach((child) => {
          if (child.id === secondParent) {
            // eslint-disable-next-line no-param-reassign
            child.active = true;
            // eslint-disable-next-line no-param-reassign
            child.open = true;
          } else {
            // eslint-disable-next-line no-param-reassign
            child.open = false;
          }
        });
      });
    },
    async searchMenu(event) {
      const { value } = event.target;
      if (value !== null && value.length >= 3 && value !== undefined) {
        this.searchByInput = true;
        const { data } = await Api.getOpenInformationData({
          search: value,
          all: true,
        });
        this.menusSearchResults = data.results.map((item) => ({
          id: item.id,
          name: item.name,
        }));
      } else {
        this.searchByInput = false;
      }
    },
  },
  watch: {
    // async search(val) {
    //   const params = {
    //     search: val,
    //   };
    //   this.searchDebounce(params);
    // },
  },
  mounted() {
    this.getMenudata();
    this.searchDebounce = debounce(async (params) => {
      const { data } = await Api.getThirdLevelItems(params);
      const { results } = data;
      this.findParents(results.map(({ id }) => id));
    }, 1000);
  },
};
</script>

<style lang="scss" src="./ReportsByFiled.scss"></style>
