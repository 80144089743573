<template>
  <div>
    <div class="loading-component-bar" v-show="componentLoading">
      <span class="icon icon-loader"></span>
    </div>
    <div v-show="!componentLoading" class="population-wrapper">
      <div class="population-year">
        <div class="years-wrapper w-100" v-if="years.length > 0">
          <h2 id="years-title">{{ $t("chooseYear") }}</h2>
          <div class="years-actions">
            <div class="years-btns-play">
              <button @click="setPlay()" :class="{ active: play }">
                <span class="icon icon-play"></span>
              </button>
            </div>
            <div
              class="years-btns"
              :class="{ disable: play }"
              v-bind:style="{ height: buttonShowline * 66 + 'px' }"
            >
              <button
                v-for="year in years"
                :key="year.value"
                @click="setYear(year.value)"
                :class="{ active: year.active }"
              >
                {{ year.name }}
              </button>
            </div>
          </div>
          <button
            v-if="buttonShowline * 9 < years.length"
            @click="buttonShowline += 1"
            class="years-add-button"
          >
            {{ $t("seeMore") }}
          </button>
        </div>
      </div>
      <div class="population-map">
      <div style="padding: 0 43%;"><AttentionKartogramma /></div>
        <button @click="downloadJpg" class="download-jpg-population">
          {{ $t("downloadPng") }}
        </button>
        <transition enter-class="out" leave-to-class="out">
          <highcharts
            v-if="loaded"
            :constructorType="'mapChart'"
            :options="chartOptions"
            ref="chartPoplulation"
            class="chart-wrapper1 base"
          ></highcharts>
          <!-- <highcharts
          :constructorType="'mapChart'"
          :options="chartOptions2"
          ref="chartPoplulation2"
          class="chart-wrapper2"
        ></highcharts> -->
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import uzMap from '@highcharts/map-collection/countries/uz/uz-all.geo.json';
import Api from '@/api/Api';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import AttentionKartogramma from '@/components/AttentionKartogramma';

export default {
  name: 'MapPopulation',
  components:{
    AttentionKartogramma,
  },
  data() {
    return {
      chartOptions: {
        chart: {
          map: uzMap,
          animation: false,
        },
        title: {
          useHTML: true,
          text: '<span>Aholi soni</span>',
          align: 'left',
          style: {
            'font-family': 'Aeroport',
            'font-size': '1.25rem',
            'font-weight': '500',
            'line-height': '26px',
            color: '#2E3236',
            'margin-top': '10px',
          },
        },
        rangeSelector: {
          enabled: false,
        },
        navigator: {
          enabled: false,
        },
        legend: {
          align: 'center',
          itemMarginTop: 30,
        },
        plotOptions: {
          map: {
            nullInteraction: true,
            allAreas: false,
            clip: false,
            affectsMapView: false,
            borderWidth: 1,
          },
          series: {
            events: {
              // eslint-disable-next-line consistent-return
              legendItemClick() {
                const clickedSeries = this;
                const { series } = clickedSeries.chart;
                const allSeriesVis = series.map(({ visible }) => visible);
                let isSeriesVis;

                allSeriesVis[clickedSeries.index] = !allSeriesVis[clickedSeries.index];
                // eslint-disable-next-line prefer-const
                isSeriesVis = allSeriesVis.some((vis) => vis);
                if (!isSeriesVis) return false;
              },
            },
            states: {
              hover: {
                opacity: 0.8,
              },
            },
          },
        },
        borderColor: 'silver',
        colors: ['#F73F00', '#F7A200', '#FFD533', '#50D800'],
        series: [
          {
            name: 'Random data',
            data: [
              ['uz-fa', 10],
              ['uz-tk', 11],
            ],
          },
          {
            name: 'Random data 2',
            data: [
              ['uz-ta', 0],
              ['uz-bu', 0],
              ['uz-kh', 0],
              ['uz-qr', 0],
              ['uz-nw', 0],
              ['uz-sa', 0],
            ],
          },
          {
            name: 'Random data 3',
            data: [
              ['uz-an', 0],
              ['uz-ng', 0],
              ['uz-ji', 0],
              ['uz-si', 0],
            ],
          },
          {
            name: 'Random data 4',
            data: [
              ['uz-qa', 0],
              ['uz-su', 0],
            ],
          },
        ],
      },
      // chartOptions2: {
      //   chart: {
      //     map: uzMap,
      //   },
      //   title: {
      //     text: '',
      //     align: 'left',
      //   },
      //   rangeSelector: {
      //     enabled: false,
      //   },
      //   navigator: {
      //     enabled: false,
      //   },
      //   plotOptions: {
      //     map: {
      //       nullInteraction: false,
      //       allAreas: false,
      //       clip: false,
      //       showInLegend: false,
      //       affectsMapView: false,
      //       borderWidth: 1,
      //       borderColor: '#cccccc',
      //     },
      //     series: {
      //       events: {
      //         // eslint-disable-next-line consistent-return
      //         legendItemClick() {
      //           const clickedSeries = this;
      //           const { series } = clickedSeries.chart;
      //           const allSeriesVis = series.map(({ visible }) => visible);
      //           let isSeriesVis;

      //           allSeriesVis[clickedSeries.index] = !allSeriesVis[clickedSeries.index];
      //           // eslint-disable-next-line prefer-const
      //           isSeriesVis = allSeriesVis.some((vis) => vis);
      //           if (!isSeriesVis) return false;
      //         },
      //       },
      //       states: {
      //         hover: {
      //           opacity: 0.8,
      //         },
      //       },
      //     },
      //   },
      //   borderWidth: 1,
      //   borderColor: '#cccccc',
      //   borderRadius: 3,
      //   colors: ['#f7f7f7'],
      //   series: [
      //     {
      //       name: 'Random data',
      //       data: [
      //         ['uz-fa', 10],
      //         ['uz-tk', 11],
      //       ],
      //     },
      //     {
      //       name: 'Random data 2',
      //       data: [
      //         ['uz-ta', 0],
      //         ['uz-bu', 0],
      //         ['uz-kh', 0],
      //         ['uz-qr', 0],
      //         ['uz-nw', 0],
      //         ['uz-sa', 0],
      //       ],
      //     },
      //     {
      //       name: 'Random data 3',
      //       data: [
      //         ['uz-an', 0],
      //         ['uz-ng', 0],
      //         ['uz-ji', 0],
      //         ['uz-si', 0],
      //       ],
      //     },
      //     {
      //       name: 'Random data 4',
      //       data: [
      //         ['uz-qa', 0],
      //         ['uz-su', 0],
      //       ],
      //     },
      //   ],
      // },
      currentYear: null,
      currentIndex: 0,
      years: [],
      colors: {
        4: ['#F73F00', '#F7A200', '#F7E300', '#50D800'],
        5: ['#F73F00', '#F7A200', '#F7BB00', '#F7E300', '#50D800'],
        6: ['#F73F00', '#F7A200', '#F7BB00', '#F7E300', '#50D800', '#3E9D00'],
        7: [
          '#F73F00',
          '#F7A200',
          '#F7BB00',
          '#F5D000',
          '#F7E300',
          '#50D800',
          '#3E9D00',
        ],
        8: [
          '#F73F00',
          '#F7A200',
          '#F7BB00',
          '#F5D000',
          '#F7E300',
          '#50D800',
          '#3E9D00',
          '#217100',
        ],
      },
      play: false,
      cartogramCount: 4,
      cartogramData: null,
      buttonShowline: 2,
      timer: null,
      componentLoading: true,
      mapRegionuz: {
        'UZ.FA': this.$t('fergana'),
        'UZ.TK': this.$t('tashkent'),
        'UZ.AN': this.$t('andijan'),
        'UZ.NG': this.$t('namangan'),
        'UZ.JI': this.$t('jizzakh'),
        'UZ.SI': this.$t('syrdarya'),
        'UZ.TA': this.$t('tashkentRegion'),
        'UZ.BU': this.$t('bukhara'),
        'UZ.KH': this.$t('khorezm'),
        'UZ.QR': this.$t('republicofKarakalpakstan'),
        'UZ.NW': this.$t('navoi'),
        'UZ.SA': this.$t('samarkand'),
        'UZ.QA': this.$t('kashkadarya'),
        'UZ.SU': this.$t('surkhandarya'),
      },
      currentId: null,
      loaded: false,
    };
  },
  methods: {
    downloadJpg() {
      const svg = document.querySelector('.chart-wrapper1');
      if (svg == null) {
        // eslint-disable-next-line no-alert
        alert(this.$t('pleaseWaitForDiagramFullyLoad'));
      } else {
        html2canvas(svg).then((canvas) => {
          canvas.toBlob((blob) => {
            saveAs(blob, `${this.chartOptions.title.text}Image.jpg`);
          });
        });
      }
    },
    setYear(year) {
      this.years.forEach((el) => {
        const isEqual = el.value === year;
        if (isEqual) {
          // eslint-disable-next-line no-param-reassign
          el.active = true;
          this.currentYear = year;
        } else {
          // eslint-disable-next-line no-param-reassign
          el.active = false;
        }
      });
      this.loaded = false;
      this.getCartogramData(year);
    },
    setYears() {
      this.currentIndex = this.years.findIndex(
        (el) => String(el.value) === String(this.currentYear),
      );
      this.currentIndex += 1;
      if (this.currentIndex >= this.years.length) {
        this.currentIndex = 0;
        this.currentYear = this.years[this.currentIndex].value;
        this.setYear(this.currentYear);
        this.getData();
      } else {
        this.currentYear = this.years[this.currentIndex].value;
        this.setYear(this.currentYear);
        this.getData();
      }
    },
    async setPlay() {
      this.play = !this.play;
      if (this.play) {
        this.setYears();
      } else {
        clearTimeout(this.timer);
      }
    },
    async getData() {
      this.timer = setTimeout(this.setYears, 5000);
    },
    calculateData(data) {
      // formula max/(4/(n+1))
      const setRanges = (maxValue = 0) => {
        const positions = [];
        for (let i = 0; i < this.cartogramCount; i += 1) {
          positions.push(i);
        }
        const rangeArray = [];
        positions.reduce((position, n) => {
          const devider = this.cartogramCount / (n + 1);
          const rangeValue = Math.round((maxValue / parseFloat(devider))*10)/10;
          if (n === 0) {
            rangeArray.push([position, parseFloat(rangeValue)]);
          } else {
            rangeArray.push([position + 1, parseFloat(rangeValue)]);
          }
          return parseFloat(rangeValue);
        }, 0);
        return rangeArray;
      };
      const codeArray = data.filter((item) => item.map_code !== null);
      const seriesDataArray = codeArray.map(
        ({ map_code: code, data: valueArr }) => [
          code,
          Object.values(valueArr[0]).shift(),
        ],
      );
      const maxValue = Math.max(...seriesDataArray.map(([, value]) => value));
      const rangeArray = setRanges(maxValue);
      const filteredData = rangeArray.map(([min, max]) => ({
        name: `${min}-${max}`,
        data: seriesDataArray.filter((series) => {
          const [, value] = series;
          if (
            value - 1 >= parseFloat(min - 1)
            && value - 1 <= parseFloat(max)
          ) {
            return series;
          }
          return null;
        }),
      }));
      return filteredData;
    },
    async getCartogramData(year) {
      const { data } = await Api.getCartogramData(this.currentId, { year });
      this.chartOptions.series = this.calculateData(data);
      this.chartOptions.colors = this.colors[this.cartogramCount];
      this.loaded = true;
      this.componentLoading = false;
      // this.chartOptions2.series = this.calculateData(data);
    },
    changeMapLang() {
      uzMap.features.forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        item.properties.name = this.mapRegionuz[item.id];
      });
    },
    async getYearsList() {
      const { data } = await Api.getYearsData(this.currentId);
      const { name, years } = data;
      this.classifierName = name;
      this.years = years.map((year) => ({
        name: year,
        value: year,
        active: false,
      }));
      this.buttonShowline = this.years.length > 9 ? 2 : 1;
      this.setYear(this.years[0].value);
      let miniTexts = '';
      name.split(' ').forEach((item) => {
        miniTexts += `<p>${item} </p> `;
      });
      this.chartOptions.title.text = `<span id="mapData-title">${miniTexts}</span>`;
      // this.chartOptions2.title.text = name;
    },
    async changecomponentValue(id) {
      this.componentLoading = true;
      this.loaded = false;
      this.changeMapLang();
      this.cartogramCount = localStorage.getItem('cartogramCount') !== 'null'
        ? Number(localStorage.getItem('cartogramCount'))
        : 4;
      this.currentId = id;
      await this.getYearsList();
    },
  },
  mounted() {
    this.$emit('ready');
  },
};
</script>

<style lang="scss" src="./MapPopulation.scss"></style>
