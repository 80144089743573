<template>
  
  <div>
    <div class="loading-component-bar" v-show="componentLoading">
      <span class="icon icon-loader"></span>
    </div>
    <div v-show="!componentLoading">
      <div class="area-years">
        <div class="years-wrapper w-100">
          <h2 id="years-title">{{ $t("chooseYear") }}</h2>
          <div class="years-actions">
            <div class="years-btns-play">
              <button @click="setPlay()" :class="{ active: play }">
                <span class="icon icon-play"></span>
              </button>
            </div>
            <div class="years-btns" :class="{ disable: play }">
              <button
                v-for="(year, i) in buttonsYears"
                :key="i"
                @click="setYear(year.value)"
                :class="{ active: year.active }"
              >
                {{ year.name }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="area-wrapper">
        <div class="area-header">

          <h2 id="h2">{{ activeMenu }}</h2>

          <div class="number__box">
            <input class="number__input" @click="ChangeStatus(1)" type="checkbox" id="checkbox"><label class="nuber__label" for="checkbox">{{ $t("number") }}</label>
          </div>
      
          <div><AttentionGrafic /></div>
          
   

          
          
          
          <div class="dropdown-wrapper">
            <button @click="downloadSvg">{{ $t("downloadPng") }}</button>
            
            <div class="v-select-wrapper">
              <v-select
                outlined
                v-model="regionSelectValue"
                :items="regionsList"
                @change="
                  () => {
                    this.showDistrict = true;
                    this.getAreaRegionChartData();
                  }
                "
                :placeholder="$t('selectRegion')"
                item-text="name"
                item-value="value"
                hide-details
              ></v-select>
            </div>
          </div>
        </div>
        <div v-if="(this.countt % 2 == 0)">
          <AreaChart
            class="areaChartClass withoutNumber"
            ref="areaChart"
            :regiondata="regionsData"
            :years="years"
            :uzbekistanData="dataUzbekistan"
          />
        </div>
        <div v-else>
          <AreaChart
            class="areaChartClass"
            ref="areaChart"
            :regiondata="regionsData"
            :years="years"
            :uzbekistanData="dataUzbekistan"
          />
        </div>
      </div>
      <div class="area-wrapper" :class="showDistrict ? 'show' : 'hide'">
        <div class="area-header">
          <h2 id="h2">{{ activeRegionMenu }}</h2>
          <div class="dropdown-wrapper">
            <button @click="downloadRegionSvg">{{ $t("downloadPng") }}</button>
            <div class="v-select-wrapper">
              <v-select
                outlined
                v-model="selectedDistrict"
                :items="districtList"
                @change="getAreaRegionChartData($event)"
                :placeholder="$t('selectRegion')"
                item-text="name"
                item-value="value"
                hide-details
              ></v-select>
            </div>
          </div>
        </div>
        <area-chart-region
          ref="areaChartRegion"
          class="areaChartRegion"
          :regiondata="regionsData"
          :years="years"
          :uzbekistanData="dataUzbekistan"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Api from '@/api/Api';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import AreaChart from './AreaChart/AreaChart.vue';

import AreaChartRegion from './AreaChart/AreaChartRegion.vue';

import AttentionGrafic from '../../../../components/AttentionGrafic/AttentionGrafic.vue'




/* eslint-disable no-alert */

export default {
  name: 'AreaDataComponent',
  
 
  data() {
    return {

      showDistrict: false,
      activeMenu: 'Aholi soni',
      activeRegionMenu: null,
      districtList: null,
      districtData: null,
      play: false,
      time: null,
      currentYear: new Date().getFullYear(),
      currentIndex: 0,
      selectedDistrict: null,
      buttonsYears: [],
      regionsList: [],
      regionSelectValue: 1,
      regionSelectValueBig: 1,
      AreaData: '',
      currentId: 0,
      countt: 2,
      years: [
        '2000',
        '2001',
        '2005',
        '2007',
        '2009',
        '2011',
        '2013',
        '2015',
        '2018',
        '2020',
        '2022',
      ],
      dataUzbekistan: {
        title: "O'zbekiston Respublikasi",
        values: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      regionsData: {
        title: 'Toshkent shahri',
        values: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      currentLang: localStorage.getItem('language')
        ? localStorage.getItem('language')
        : 'uz',
      componentLoading: true,
    };
  },
  methods: {

    setYear(year) {
      this.chartBarYear = year;
      this.showMiniRegionList = false;
      this.buttonsYears.forEach((el) => {
        const isEqual = el.value === year;
        if (isEqual) {
          // eslint-disable-next-line no-param-reassign
          el.active = true;
          this.currentYear = year;
        } else {
          // eslint-disable-next-line no-param-reassign
          el.active = false;
        }
      });
      this.getAreaRegionChartData();
    },
    async getData() {
      this.timer = setTimeout(this.setYears, 5000);
    },
    setYears() {
      this.currentIndex = this.buttonsYears.findIndex(
        (el) => String(el.value) === String(this.currentYear),
      );
      this.currentIndex += 1;
      if (this.currentIndex >= this.buttonsYears.length) {
        this.currentIndex = 0;
        this.currentYear = this.buttonsYears[this.currentIndex].value;
      } else {
        this.currentYear = this.buttonsYears[this.currentIndex].value;
      }
      this.setYear(this.currentYear);
      this.getData();
    },
    async setPlay() {
      this.play = !this.play;
      if (this.play) {
        this.setYears();
      } else {
        clearTimeout(this.timer);
      }
    },
    downloadSvg() { 
      const svg = document.querySelector('.areaChartClass');
      if (svg == null) {
        alert(this.$t('pleaseWaitForDiagramFullyLoad'));
      } else {
        const title = document.createElement('h2');
        title.textContent = this.activeMenu;
        title.style.textAlign = 'center';
        const toolBar = document.querySelectorAll('.apexcharts-toolbar');
        for (let i = 0; i < toolBar.length; i += 1) {
          toolBar[i].style.display = 'none';
        }
        svg.prepend(title);
        html2canvas(svg).then((canvas) => {
          canvas.toBlob((blob) => {
            saveAs(blob, `${this.activeMenu}Image.jpg`);
          });
        });
        title.remove();
        for (let i = 0; i < toolBar.length; i += 1) {
          toolBar[i].style.display = 'flex';
        }
      }
    },
    async ChangeStatus(datlab) {
      this.countt += datlab;
    },
    downloadRegionSvg() {
      const svg = document.querySelector('.areaChartRegion');
      if (svg == null) {
        alert(this.$t('pleaseWaitForDiagramFullyLoad'));
      } else {
        const title = document.createElement('h2');
        title.textContent = this.activeRegionMenu;
        title.style.textAlign = 'center';
        const toolBar = document.querySelectorAll('.apexcharts-toolbar');
        for (let i = 0; i < toolBar.length; i += 1) {
          toolBar[i].style.display = 'none';
        }
        svg.prepend(title);
        html2canvas(svg).then((canvas) => {
          canvas.toBlob((blob) => {
            saveAs(blob, `${this.activeRegionMenu}image.png`);
          });
        });
        title.remove();
        for (let i = 0; i < toolBar.length; i += 1) {
          toolBar[i].style.display = 'flex';
        }
      }
    },

   
    async getAreaChartData(id) {
      const year = this.buttonsYears.find(
        (item) => item.value === this.currentYear,
      );
      const Uzbekistan = await Api.getAreaChartData(id, {
        classifier_item: this.regionSelectValueBig,
        timestamps: year.data.reverse().join(),
      });
      const labels = [];
      //
      const dataUzbekistan = {
        title: Uzbekistan.data[0][`name_${this.currentLang}`]
          ? Uzbekistan.data[0][`name_${this.currentLang}`]
          : Uzbekistan.data[0].name,
        values: [],
      };
      Uzbekistan.data[0].data.forEach((item) => {
        Object.keys(item).forEach((key) => {
          labels.push(key);
          dataUzbekistan.values.push({
            x: key || 'null',
            y: item[key],
          });
        });
      });
      //
      let { data } = await Api.getAreaChartData(id, {

        timestamps: year.data.reverse().join(),
      });

      

      
      
      if (!data || data.length === 0) {
        data = [
          {
            name: this.$t('informationNotAvailable'),
            children: [],
            data: dataUzbekistan.values.map(() => 0),
          },
        ];
      }

      if (data.length == 1){
        
      
      this.years = labels;
      this.dataUzbekistan = dataUzbekistan;
     
      const chartdata1 = {
        dataUz: dataUzbekistan,
        
      };
      
        this.$refs.areaChart.updateLineChart1(chartdata1);
      
      
      } else {
      const dataRegion = {
        title: data[1][`name_${this.currentLang}`]
          ? data[1][`name_${this.currentLang}`]
          : data[1].name,
        values: [],
      };
      const dataRegion1 = {
        title: data[2][`name_${this.currentLang}`]
          ? data[2][`name_${this.currentLang}`]
          : data[2].name,
        values: [],
      };
      const dataRegion2 = {
        title: data[3][`name_${this.currentLang}`]
          ? data[3][`name_${this.currentLang}`]
          : data[3].name,
        values: [],
      };

      const dataRegion3 = {
        title: data[4][`name_${this.currentLang}`]
          ? data[4][`name_${this.currentLang}`]
          : data[4].name,
        values: [],
      };
      const dataRegion4 = {
        title: data[5][`name_${this.currentLang}`]
          ? data[5][`name_${this.currentLang}`]
          : data[5].name,
        values: [],
      };
      const dataRegion5 = {
        title: data[6][`name_${this.currentLang}`]
          ? data[6][`name_${this.currentLang}`]
          : data[6].name,
        values: [],
      };
      const dataRegion6 = {
        title: data[7][`name_${this.currentLang}`]
          ? data[7][`name_${this.currentLang}`]
          : data[7].name,
        values: [],
      };
      const dataRegion7 = {
        title: data[8][`name_${this.currentLang}`]
          ? data[8][`name_${this.currentLang}`]
          : data[8].name,
        values: [],
      };
      const dataRegion8 = {
        title: data[9][`name_${this.currentLang}`]
          ? data[9][`name_${this.currentLang}`]
          : data[9].name,
        values: [],
      };
      const dataRegion9 = {
        title: data[10][`name_${this.currentLang}`]
          ? data[10][`name_${this.currentLang}`]
          : data[10].name,
        values: [],
      };
      const dataRegion10 = {
        title: data[11][`name_${this.currentLang}`]
          ? data[11][`name_${this.currentLang}`]
          : data[11].name,
        values: [],
      };
      const dataRegion11 = {
        title: data[12][`name_${this.currentLang}`]
          ? data[12][`name_${this.currentLang}`]
          : data[12].name,
        values: [],
      };
      const dataRegion12 = {
        title: data[13][`name_${this.currentLang}`]
          ? data[13][`name_${this.currentLang}`]
          : data[13].name,
        values: [],
      };
      const dataRegion13 = {
        title: data[14][`name_${this.currentLang}`]
          ? data[14][`name_${this.currentLang}`]
          : data[14].name,
        values: [],
      };
      
      
      data[1].data.forEach((item) => {
        Object.keys(item).forEach((key) => {
          dataRegion.values.push({ x: key || 'null', y: item[key] });
        });
      });
      data[2].data.forEach((item) => {
        Object.keys(item).forEach((key) => {
          dataRegion1.values.push({ x: key || 'null', y: item[key] });
        });
      });
      data[3].data.forEach((item) => {
        Object.keys(item).forEach((key) => {
          dataRegion2.values.push({ x: key || 'null', y: item[key] });
        });
      });
      data[4].data.forEach((item) => {
        Object.keys(item).forEach((key) => {
          dataRegion3.values.push({ x: key || 'null', y: item[key] });
        });
      });
      data[5].data.forEach((item) => {
        Object.keys(item).forEach((key) => {
          dataRegion4.values.push({ x: key || 'null', y: item[key] });
        });
      });
      data[6].data.forEach((item) => {
        Object.keys(item).forEach((key) => {
          dataRegion5.values.push({ x: key || 'null', y: item[key] });
        });
      });
      data[7].data.forEach((item) => {
        Object.keys(item).forEach((key) => {
          dataRegion6.values.push({ x: key || 'null', y: item[key] });
        });
      });
      data[8].data.forEach((item) => {
        Object.keys(item).forEach((key) => {
          dataRegion7.values.push({ x: key || 'null', y: item[key] });
        });
      });
      data[9].data.forEach((item) => {
        Object.keys(item).forEach((key) => {
          dataRegion8.values.push({ x: key || 'null', y: item[key] });
        });
      });
      data[10].data.forEach((item) => {
        Object.keys(item).forEach((key) => {
          dataRegion9.values.push({ x: key || 'null', y: item[key] });
        });
      });
      data[11].data.forEach((item) => {
        Object.keys(item).forEach((key) => {
          dataRegion10.values.push({ x: key || 'null', y: item[key] });
        });
      });
      data[12].data.forEach((item) => {
        Object.keys(item).forEach((key) => {
          dataRegion11.values.push({ x: key || 'null', y: item[key] });
        });
      });
      data[13].data.forEach((item) => {
        Object.keys(item).forEach((key) => {
          dataRegion12.values.push({ x: key || 'null', y: item[key] });
        });
      });
      data[14].data.forEach((item) => {
        Object.keys(item).forEach((key) => {
          dataRegion13.values.push({ x: key || 'null', y: item[key] });
        });
      });

      this.years = labels;
      
      this.dataUzbekistan = dataUzbekistan;
      this.regionsData = dataRegion;

      
   
      const chartdata = {
        dataUz: dataUzbekistan,
        dataRegion,
        dataRegion1,
        dataRegion2,
        dataRegion3,
        dataRegion4,
        dataRegion5,
        dataRegion6,
        dataRegion7,
        dataRegion8,
        dataRegion9,
        dataRegion10,
        dataRegion11,
        dataRegion12,
        dataRegion13,  
          
      };
      
      
        this.$refs.areaChart.updateLineChart(chartdata);
      
      }
    },
    async getRegionList() {
      const { data } = await Api.getRegionData(this.currentId);
      this.activeMenu = data.name;
      this.regionsList = data.regions.map((item) => ({
        name: item[`name_${this.currentLang}`]
          ? item[`name_${this.currentLang}`]
          : item.name,
        value: item.id,
      }));
    },



    async getAreaRegionChartData(id) {
      const year = this.buttonsYears.find(
        (item) => item.value === this.currentYear,
      );
      let { data } = await Api.getAreaChartData(this.currentId,  {
        classifier_item: this.regionSelectValue,
        timestamps: year.data.reverse().join(),
      });
      if (!data || data.length === 0) {
        data = [
          {
            name: this.$t('informationNotAvailable'),
            children: [],
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
          },
        ];
      }
      this.activeRegionMenu = data[0][`name_${this.currentLang}`]
        ? data[0][`name_${this.currentLang}`]
        : data[0].name;

      
      const dataRegion = {
        title: data[0][`name_${this.currentLang}`]
          ? data[0][`name_${this.currentLang}`]
          : data[0].name,
        values: [],
      };
      data[0].data.forEach((item) => {
        Object.keys(item).forEach((key) => {
          dataRegion.values.push({ x: key, y: item[key] });
        });
      });
      if (data[0].children) {
        this.districtList = data[0].children.map((item) => {
          const { name, id: idItem } = item;
          return {
            name: item[`name_${this.currentLang}`]
              ? item[`name_${this.currentLang}`]
              : name,
            value: idItem,
          };
        });
        this.districtData = data[0].children.map((item) => {
          const { name, id: districtDataId } = item;
          const values = [];
          item.data.forEach((items) => {
            Object.keys(items).forEach((key) => {
              values.push({ x: key, y: items[key] });
            });
          });
          return {
            title: item[`name_${this.currentLang}`]
              ? item[`name_${this.currentLang}`]
              : name,
            values,
            id: districtDataId,
          };
        });
        if (!id) {
          this.selectedDistrict = data[0].children[0] ? data[0].children[0].id : 0;
        }
      } else {
        //
        this.districtList = null;
        this.districtData = {
          title: this.$t('informationNotAvailable'),
          children: false,
          values: this.years.map((item) => ({ x: item, y: 0 })),
        };
      }
      this.$refs.areaChartRegion.updateLineChartRegion(
        dataRegion,
        this.districtData,
        this.selectedDistrict,
      );
      this.componentLoading = false;
      this.getAreaChartData(this.currentId);
    },
    async getYearsData(id) {
      this.currentId = id;
      const { data } = await Api.getYearsData(id);
      this.buttonsYears = [];
      data.years.reverse();
      let count = Math.round(data.years.length / 10);
      if (count * 10 < data.years.length) {
        count += 1;
      }
      for (let i = 0; i < count; i += 1) {
        const currentYear = `${
          data.years[i * 10 + 9]
            ? data.years[i * 10 + 9]
            : data.years[data.years.length - 1]
        }-${data.years[i * 10]}`;
        const buttonData = [];
        for (let y = i * 10; y < i * 10 + 10; y += 1) {
          if (data.years[y]) {
            buttonData.push(data.years[y]);
          }
        }
        this.buttonsYears.push({
          name: currentYear,
          value: currentYear,
          active: false,
          data: buttonData,
        });
      }
      this.buttonsYears.reverse();
      this.setYear(this.buttonsYears[this.buttonsYears.length - 1].value);
      this.getRegionList();
    },
    changecomponentValue(id) {
      if (this.currentId !== id) {
        this.componentLoading = true;
        this.getYearsData(id);
        this.showDistrict = false;
      }
    },
  },
  components: {
    AreaChart,
    AreaChartRegion,
    AttentionGrafic
    
},
  mounted() {
    this.$emit('ready');
  },
};
</script>

<style lang="scss" src="./AreaDataComponent.scss"></style>
