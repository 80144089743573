<template>
  <div>
    <div class="loading-component-bar" v-show="componentLoading">
      <span class="icon icon-loader"></span>
    </div>
    <div>
      <div v-show="!componentLoading" class="relationship-wrapper">
        <div class="relationship-item">
          <p>{{ $t("selectRegion") }}</p>
          <v-select
            outlined
            hide-details
            :items="regionsList"
            :placeholder="$t('selectRegion')"
            item-text="name"
            item-value="id"
            clearable
            v-model="postObject.region"
            :disabled="postObject.year != null"
            @click:clear="regionPercentage = false"
            @change="getDataTable"
          >
          </v-select>
          <label class="checkbox-wrapper"
            >{{ $t("convertPercentagesFractions") }}
            <input
              type="checkbox"
              v-model="regionPercentage"
              :disabled="postObject.region == null"
              @change="getDataTable"
            />
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="relationship-item">
          <div style="display: flex;
              justify-content: space-between;
              width: 500px;">
                <p class="col">{{ $t("chooseYear") }}</p>
              
                <AttentionMunosabat />
                
              </div>









          <v-select
            outlined
            hide-details
            :items="years"
            :placeholder="$t('chooseYear')"
            item-text="name"
            item-value="value"
            clearable
            v-model="postObject.year"
            :disabled="postObject.region != null"
            @click:clear="yearPercentage = false"
            @change="getDataTable"
          ></v-select>
          <label class="checkbox-wrapper"
            >{{ $t("convertPercentagesFractions") }}
            <input
              type="checkbox"
              v-model="yearPercentage"
              :disabled="postObject.year == null"
              @change="getDataTable"
            />
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
      <div
        v-show="!tableLoaded"
        class="relationship-table-wrapper"
        v-if="dataTable.length > 0"
      >
        <div>
          <table class="relationship-table-wrapper-table">
            <thead>
              <tr>
                <th>{{ $t("regions") }}</th>
                <th v-for="item in dataTableYears" :key="item.title">
                  <span>{{ item.title }}</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in dataTable"
                :key="item.title"
                :class="{ active: item.active }"
              >
                <td>
                  <p>{{ item.title }}</p>
                </td>
                <td
                  v-for="count in item.counter"
                  :key="count.id"
                  :class="{ active: count.active }"
                >
                  <span>{{ count.value }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-show="tableLoaded" class="relationship-table-wrapper-loader">
        <span class="icon icon-loader"></span>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '@/api/Api';
import AttentionMunosabat from '@/components/AttentionMunosabat';

export default {
  name: 'RelationshipData',
  components: {
    AttentionMunosabat,
  },
  data() {
    return {
      years: [],
      regionsList: [],
      dataTableYears: [],
      dataTable: [],
      postObject: {
        year: null,
        region: null,
      },
      yearPercentage: false,
      regionPercentage: false,
      currentId: null,
      tableLoaded: false,
      currentLang: localStorage.getItem('language')
        ? localStorage.getItem('language')
        : 'uz',
      componentLoading: true,
    };
  },
  methods: {
    changecomponentValue(id) {
      this.componentLoading = true;
      this.dataTable = [];
      this.currentId = id;
      this.getRegionsList(id);
      this.getYearList(id);
    },
    async getRegionsList(id) {
      const { data } = await Api.getRegionsList(id);
      const { regions } = data;
      this.regionsList = regions.map((item) => ({
        name: item[`name_${this.currentLang}`]
          ? item[`name_${this.currentLang}`]
          : item.name,
        id: item.id,
      }));
    },
    async getYearList(id) {
      const { data } = await Api.getYearsData(id);
      const { years } = data;
      this.years = years
        ? years.map((year) => ({
          name: year,
          value: year,
        }))
        : null;
      this.componentLoading = false;
    },
    async getDataTable() {
      if (Object.values(this.postObject).every((value) => value == null)) { return; }
      const params = {
        region: this.postObject.region,
        year: this.postObject.year,
        percent: 0,
      };
      if (this.postObject.year !== null) {
        params.percent = this.yearPercentage === true ? 1 : 0;
      }

      if (this.postObject.region !== null) {
        params.percent = this.regionPercentage === true ? 1 : 0;
      }
      this.tableLoaded = true;
      const { data } = await Api.getRelationshipsTableData(
        this.currentId,
        params,
      );
      this.dataTableYears = data[0].data.map((item) => ({
        title: Object.keys(item).shift(),
      }));
      this.dataTable = data.map((item) => {
        const {
          id: parentId, name, data: counts, parent_id: parent,
        } = item;
        return {
          id: parentId,
          title: item[`name_${this.currentLang}`]
            ? item[`name_${this.currentLang}`]
            : name,
          active: false,
          parent,
          counter: counts.map((count) => {
            let countActive = false;
            if (this.postObject.year !== null) {
              countActive = Object.keys(count).shift() === this.postObject.year;
            }
            return {
              id: this.idGenerator(),
              value: Number(Object.values(count).shift())
                .toString()
                .replace('.', ','),
              active: countActive,
            };
          }),
        };
      });
      if (this.postObject.region !== null) {
        this.dataTable.forEach((item, i) => {
          if (item.id === this.postObject.region) {
            // eslint-disable-next-line no-param-reassign
            item.active = true;
            this.dataTable.splice(i, 1);
            this.dataTable.splice(i === 0 ? 0 : 1, 0, item);
            const children = this.dataTable
              .filter((child) => child.parent === item.id)
              .reverse();
            children.forEach((child) => {
              this.dataTable.splice(
                this.dataTable.findIndex((find) => find.id === child.id),
                1,
              );
              this.dataTable.splice(2, 0, child);
            });
          }
        });
      }
      this.tableLoaded = false;
    },
    idGenerator() {
      return `${Math.random().toString(36).substr(2, 9)}`;
    },
  },
  mounted() {
    this.$emit('ready');
  },
};
</script>

<style lang="scss" src="./RelationshipData.scss"></style>
