<template>
  <div>
    <div id="timeline-chart"></div>

  </div>
</template>
<script>
import VueApexCharts from 'apexcharts';

export default {
  name: 'AreaChart',
  props: ['regiondata', 'years', 'uzbekistanData'],
  data() {
    return {
      gradient: null,
      gradient2: null,
      lineChart: null,
    };
  },
  methods: {
    updateLineChart(data) {
      this.createLineChart(data);
    },
    updateLineChart1(data) {
      this.createLineChart1(data);
    },
    createLineChart1(data) {
      
      const options = {
        chart: {
          type: 'area',
          height: 600,
          width: '100%',
          stacked: false,
          toolbar: {
            show: true,
            tools: {
              download: false,
              selection: false,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: false,
              reset: false,
            },
          },
          dropShadow: {
            enabled: true,
            enabledSeries: [0],
            top: -2,
            left: 2,
            blur: 5,
            opacity: 0.06,
          },
        },
        colors: ['#144272', '#7f8133','#26a9e1' , '#edb3d2', '#94B5C0','#e51b4c','#f58230','#fee10f','#c0d848','#3cb44b','#4e64ae','#863d97','#9a6427','#62429a','#469990'],
        stroke: {
          curve: 'smooth',
          width: 2,
        },
        dataLabels: {
          enabled: true,
          offsetY: -5,
          style: {
            padding:10,
            fontSize: '14px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 'bold',
            colors: undefined,
        },
        background: { 
        enabled: true,
        foreColor: '#153462',
        padding: 4,
        colors: undefined,
        opacity: 0,
        
      },
        },
        
        series: [{
          name: data.dataUz ? data.dataUz.title : 'null',
          data: data.dataUz ? data.dataUz.values : ['2000', 18],
        },
        ],
        markers: {
          size: 5,
        },
        yaxis: {
          tooltip: {
            enabled: true,
          },
        },
        grid: {
          show: true,
          
          strokeDashArray: 0,
          position: 'back',
          xaxis: {
              lines: {
                  show: true
              }
          },   
          yaxis: {
              lines: {
                  show: true
              }
          },  
          row: {
              colors: undefined,
              opacity: 0.5
          },  
          column: {
              colors: undefined,
              opacity: 0.5
          },
          padding: {
            left: 20,
            right: 40,
          },
        },
        tooltip: {
          x: {
            format: 'yyyy',
          },
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
          itemMargin: {
            vertical: 20,
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 0, 0],
          },
        },
        pointHoverBorderColor:{
          type: 'gradient2',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0.8,
            stops: [0, 90, 100],
          },
        },
      };
      if (this.lineChart ) {
        this.lineChart.updateSeries([{
          name: data.dataUz ? data.dataUz.title : 'null',
          data: data.dataUz ? data.dataUz.values : ['2000', 18],
        },
      
      ]);
      } else {
        this.lineChart = new VueApexCharts(document.querySelector('#timeline-chart'), options);
        this.lineChart.render();
      }
    },
    createLineChart(data) {
      const options = {
        chart: {
          type: 'area',
          height: 600,
          width: '100%',
          stacked: false,
          toolbar: {
            show: true,
            tools: {
              download: false,
              selection: false,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: false,
              reset: false,
            },
          },
          dropShadow: {
            enabled: true,
            enabledSeries: [0],
            top: -2,
            left: 2,
            blur: 5,
            opacity: 0.06,
          },
        },
        colors: [ '#144272','#7f8133','#26a9e1' , '#edb3d2', '#94B5C0','#e51b4c','#f58230','#fee10f','#c0d848','#3cb44b','#4e64ae','#863d97','#9a6427','#62429a','#469990'],
        stroke: {
          curve: 'smooth',
          width: 2,
        },
       
        dataLabels: {
          enabled: true,
          offsetY: -5,
          style: {
            padding:10,
            fontSize: '14px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 'bold',
            colors: undefined,
        },
        background: { 
        enabled: true,
        foreColor: '#153462',
        padding: 4,
        colors: undefined,
        opacity: 0,
        
      },
        },
        
        series: [{
          name: data.dataUz ? data.dataUz.title : 'null',
          data: data.dataUz ? data.dataUz.values : ['2000', 18],
        },
        {
          name: data.dataRegion ? data.dataRegion.title : 'null',
          data: data.dataRegion ? data.dataRegion.values : ['2000', 118],
        },
        {
          name: data.dataRegion1 ? data.dataRegion1.title : 'null',
          data: data.dataRegion1 ? data.dataRegion1.values : ['2000', 118],
        },
        {
          name: data.dataRegion2 ? data.dataRegion2.title : 'null',
          data: data.dataRegion2 ? data.dataRegion2.values : ['2000', 118],
        },
        {
          name: data.dataRegion3 ? data.dataRegion3.title : 'null',
          data: data.dataRegion3 ? data.dataRegion3.values : ['2000', 118],
        },
        {
          name: data.dataRegion4 ? data.dataRegion4.title : 'null',
          data: data.dataRegion4 ? data.dataRegion4.values : ['2000', 118],
        },
        {
          name: data.dataRegion5 ? data.dataRegion5.title : 'null',
          data: data.dataRegion5 ? data.dataRegion5.values : ['2000', 118],
        },
        {
          name: data.dataRegion6 ? data.dataRegion6.title : 'null',
          data: data.dataRegion6 ? data.dataRegion6.values : ['2000', 118],
        },
        {
          name: data.dataRegion7 ? data.dataRegion7.title : 'null',
          data: data.dataRegion7 ? data.dataRegion7.values : ['2000', 118],
        },
        {
          name: data.dataRegion8 ? data.dataRegion8.title : 'null',
          data: data.dataRegion8 ? data.dataRegion8.values : ['2000', 118],
        },
        {
          name: data.dataRegion9 ? data.dataRegion9.title : 'null',
          data: data.dataRegion9 ? data.dataRegion9.values : ['2000', 118],
        },
        {
          name: data.dataRegion10 ? data.dataRegion10.title : 'null',
          data: data.dataRegion10 ? data.dataRegion10.values : ['2000', 118],
        },
        {
          name: data.dataRegion11 ? data.dataRegion11.title : 'null',
          data: data.dataRegion11 ? data.dataRegion11.values : ['2000', 118],
        },
        {
          name: data.dataRegion12 ? data.dataRegion12.title : 'null',
          data: data.dataRegion12 ? data.dataRegion12.values : ['2000', 118],
        },
        {
          name: data.dataRegion13 ? data.dataRegion13.title : 'null',
          data: data.dataRegion13 ? data.dataRegion13.values : ['2000', 118],
        },
        ],
        markers: {
          size: 5,
         
        },
        yaxis: {
          tooltip: {
            enabled: true,
          },
        },
        grid: {
          show: true,
          
          strokeDashArray: 0,
          position: 'back',
          xaxis: {
              lines: {
                  show: true
              }
          },   
          yaxis: {
              lines: {
                  show: true
              }
          },  
          row: {
              colors: undefined,
              opacity: 0.5
          },  
          column: {
              colors: undefined,
              opacity: 0.5
          },
          padding: {
            left: 20,
            right: 40,
          },
        },
        tooltip: {
          x: {
            format: 'yyyy',
          },
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
          itemMargin: {
            vertical: 20,
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 0, 0],
          },
        },
        
      };
      
      if (this.lineChart ) {
        this.lineChart.updateSeries([{
          name: data.dataUz ? data.dataUz.title : 'null',
          data: data.dataUz ? data.dataUz.values : ['2000', 18],
        }, {
          name: data.dataRegion ? data.dataRegion.title : 'null',
          data: data.dataRegion ? data.dataRegion.values : ['2000', 118],
        },
        {
          name: data.dataRegion1 ? data.dataRegion1.title : 'null',
          data: data.dataRegion1 ? data.dataRegion1.values : ['2000', 118],
        },
        {
          name: data.dataRegion2 ? data.dataRegion2.title : 'null',
          data: data.dataRegion2 ? data.dataRegion2.values : ['2000', 118],
        },
        {
          name: data.dataRegion3 ? data.dataRegion3.title : 'null',
          data: data.dataRegion3 ? data.dataRegion3.values : ['2000', 118],
        },
        {
          name: data.dataRegion4 ? data.dataRegion4.title : 'null',
          data: data.dataRegion4 ? data.dataRegion4.values : ['2000', 118],
        },
        {
          name: data.dataRegion5 ? data.dataRegion5.title : 'null',
          data: data.dataRegion5 ? data.dataRegion5.values : ['2000', 118],
        },
        {
          name: data.dataRegion6 ? data.dataRegion6.title : 'null',
          data: data.dataRegion6 ? data.dataRegion6.values : ['2000', 118],
        },
        {
          name: data.dataRegion7 ? data.dataRegion7.title : 'null',
          data: data.dataRegion7 ? data.dataRegion7.values : ['2000', 118],
        },
        {
          name: data.dataRegion8 ? data.dataRegion8.title : 'null',
          data: data.dataRegion8 ? data.dataRegion8.values : ['2000', 118],
        },
        {
          name: data.dataRegion9 ? data.dataRegion9.title : 'null',
          data: data.dataRegion9 ? data.dataRegion9.values : ['2000', 118],
        },
        {
          name: data.dataRegion10 ? data.dataRegion10.title : 'null',
          data: data.dataRegion10 ? data.dataRegion10.values : ['2000', 118],
        },
        {
          name: data.dataRegion11 ? data.dataRegion11.title : 'null',
          data: data.dataRegion11 ? data.dataRegion11.values : ['2000', 118],
        },
        {
          name: data.dataRegion12 ? data.dataRegion12.title : 'null',
          data: data.dataRegion12 ? data.dataRegion12.values : ['2000', 118],
        },
        {
          name: data.dataRegion13 ? data.dataRegion13.title : 'null',
          data: data.dataRegion13 ? data.dataRegion13.values : ['2000', 118],
        },
      ]);
      } else {
        this.lineChart = new VueApexCharts(document.querySelector('#timeline-chart'), options);
        this.lineChart.render();
      }
    },
  },
  computed: {
    regiondataVoit() {
      return this.regiondata;
    },
    yearsVoit() {
      return this.years;
    },
    uzbekistanDataVoit() {
      return this.uzbekistanData;
    },
  },
};
</script>
